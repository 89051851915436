import { removeExtraSpaces } from "src/utils";

// Inner imports
import { GroupKeywordsProps } from "./types";
import { KEYWORDS_MAX_GROUP_DEPTH } from "./constants";

export const getExactMatchKeywords = (
  keywords: Search.Keyword[],
  search: Search.Data,
): Search.Keyword[] => {
  const regexp = getExactMatchKeywordRegExp(search);

  return keywords.filter(({ string }) => regexp.test(string.toLowerCase()));
};

export const getExactMatchDuplicatedKeywords = (
  keywords: Search.Keyword[],
  search: Search.Data,
): Search.Keyword[] => {
  const regexp = getExactMatchKeywordRegExp(search);

  return keywords.filter(
    ({ string, possibleDuplicateGroupId }) =>
      regexp.test(string.toLowerCase()) && possibleDuplicateGroupId,
  );
};

export const getExactMatchNotDuplicatedKeywords = (
  keywords: Search.Keyword[],
  search: Search.Data,
): Search.Keyword[] => {
  const regexp = getExactMatchKeywordRegExp(search);

  const filteredKeywords = new Set<Search.Keyword>();

  for (const keyword of keywords) {
    const { string, possibleDuplicateGroupId, totalVolume } = keyword;

    const isExactMatchKeyword = regexp.test(string.toLowerCase());

    if (possibleDuplicateGroupId || !totalVolume || !isExactMatchKeyword)
      continue;

    filteredKeywords.add(keyword);
  }

  return [...filteredKeywords];
};

export const getDuplicatedKeywords = (
  keywords: Search.Keyword[],
): Search.Keyword[] =>
  keywords.filter(({ possibleDuplicateGroupId }) =>
    Boolean(possibleDuplicateGroupId),
  );

export const getGroupedKeywords = (
  keywords: Search.Keyword[],
  search: Search.Data,
): {
  groupedKeywords: Search.FormattedKeyword[];
  groupedDuplicates: Search.FormattedKeyword[];
} => {
  const groupedKeywords: Search.FormattedKeyword[] = [];

  const groupedDuplicates: Search.FormattedKeyword[] = [];

  const keywordsSet = new Set<string>(keywords.map(({ string }) => string));

  const collator = Intl.Collator();

  const sortedKeywords = [...keywords].sort((a, b) =>
    collator.compare(a.string, b.string),
  );

  for (const keyword of sortedKeywords) {
    groupDuplicates({
      keywords: keywordsSet,
      keyword,
      search,
      children: groupedDuplicates,
    });

    groupKeywords({
      keywords: keywordsSet,
      keyword,
      search,
      children: groupedKeywords,
    });
  }

  return {
    groupedKeywords,
    groupedDuplicates,
  };
};

function groupDuplicates({
  keywords,
  keyword,
  search,
  children,
  depth = 1,
}: GroupKeywordsProps): void {
  const { values, totalVolume, string, possibleDuplicateGroupId } = keyword;

  if (!possibleDuplicateGroupId) return;

  const { name: groupName = "" } =
    getDuplicateGroup({ keyword, depth, keywords, search }) || {};

  const parent = getKeywordsGroupParent(children, groupName);

  if (parent) {
    if (!parent.children) parent.children = [];

    parent.totalVolume += totalVolume;

    const parentValuesCollection = new Map<string, Search.KeywordValue>(
      Object.entries(parent.values),
    );

    for (const value in values) {
      const parentValue = parentValuesCollection.get(value);

      const { volume = 0 } = values[value] || {};

      if (parentValue) {
        const parentVolume = parentValue.volume;

        parentValuesCollection.set(value, {
          ...parentValue,
          volume: parentVolume + volume,
        });
      }
    }

    parent.values = Array.from(parentValuesCollection.values());

    parent.children.push({
      values,
      totalVolume,
      label: string,
      value: string,
      possibleDuplicateGroupId,
      children: [],
    });

    parent.label = groupName;
    parent.value = `${groupName}_parent`;
  } else {
    const child: Search.FormattedKeyword = {
      values,
      totalVolume,
      label: groupName,
      value: `${groupName}_parent`,
      possibleDuplicateGroupId,
      children: [
        {
          values,
          totalVolume,
          label: string,
          value: string,
          possibleDuplicateGroupId,
          children: [],
        },
      ],
    };

    children.unshift(child);

    children.sort(
      (a: Search.FormattedKeyword, b: Search.FormattedKeyword) =>
        b.totalVolume - a.totalVolume,
    );
  }
}

function groupKeywords({
  keywords,
  keyword,
  search,
  children,
  depth = 1,
}: GroupKeywordsProps): void {
  const { values, totalVolume, string, possibleDuplicateGroupId } = keyword;

  const { name: groupName = "", length: groupLength = 0 } =
    getKeywordGroup({ keyword, depth, keywords, search }) || {};

  const parent = getKeywordsGroupParent(children, groupName);

  if (parent) {
    const parentCopy = parent.children ? null : copyValue(parent);

    if (!parent.children) parent.children = [];

    parent.totalVolume += totalVolume;

    const parentValuesCollection = new Map<string, Search.KeywordValue>(
      Object.entries(parent.values),
    );

    for (const value in values) {
      const parentValue = parentValuesCollection.get(value);

      const { volume = 0 } = values[value] || {};

      if (parentValue) {
        const parentVolume = parentValue.volume;

        parentValuesCollection.set(value, {
          ...parentValue,
          volume: parentVolume + volume,
        });
      }
    }

    parent.values = Array.from(parentValuesCollection.values());

    groupKeywords({
      keywords,
      keyword,
      search,
      children: parent.children,
      depth: depth + groupLength,
    });

    if (parentCopy) parent.children.push(parentCopy);

    parent.label = groupName;
    parent.value = `${groupName}_parent`;
  } else {
    const child: Search.FormattedKeyword = {
      values,
      totalVolume,
      label: string,
      value: string,
      possibleDuplicateGroupId,
    };

    children.unshift(child);

    children.sort(
      (a: Search.FormattedKeyword, b: Search.FormattedKeyword) =>
        b.totalVolume - a.totalVolume,
    );
  }
}

function getDuplicateGroup({ keyword }: Omit<GroupKeywordsProps, "children">) {
  return {
    name: String(keyword.possibleDuplicateGroupId),
    length: 1,
  };
}

function getKeywordGroup({
  keywords,
  keyword,
  search,
  depth = 1,
}: Omit<GroupKeywordsProps, "children">) {
  if (depth > KEYWORDS_MAX_GROUP_DEPTH) return null;

  const words = keyword.string.split(" ");

  if (words.length === 1) return null;

  const formattedSearchSubject = removeExtraSpaces(
    search.subject.toLowerCase(),
  );

  for (let i = depth; i < words.length; i += 1) {
    const groupName = words.slice(0, i).join(" ");

    if (keywords.has(groupName) && groupName !== formattedSearchSubject)
      return { name: groupName, length: i };
  }

  return null;
}

function getKeywordsGroupParent(
  children: Search.FormattedKeyword[],
  groupName?: string,
): Search.FormattedKeyword | undefined {
  if (!groupName) return;

  const groupNameRegExp = getGroupNameRegExp(groupName);

  return children.find((parent: Search.FormattedKeyword) =>
    groupNameRegExp.test(parent.label),
  );
}

function getGroupNameRegExp(string: string): RegExp {
  const escapedString = string.replace(
    /[-\/\\\^\$\*\+\?\.\(\)\|\[\]\{\}]/g,
    `\\$&`,
  );

  return new RegExp(`(\\b^(${escapedString})\\b)|(\\B^(${escapedString})\\B)`);
}

function getExactMatchKeywordRegExp({ subject }: Search.Data): RegExp {
  const escapedSubject = removeExtraSpaces(subject)
    .replace(/[-\/\\^$*+?.()|[\]{}]/g, `\\$&`)
    .replace(/\s\s+/g, " ")
    .toLowerCase();

  return new RegExp(`(?:^|\\s)(${escapedSubject})(?:$|\\s)`, "i");
}

function copyValue<T>(value: T): T {
  return JSON.parse(JSON.stringify(value));
}
