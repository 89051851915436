import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./KeywordsInfo.module.scss";
import { Translation } from "src/components";
import { formatToLocaleNumber } from "src/utils";
import {
  selectLanguageById,
  selectLocationById,
  selectSearchById,
} from "src/store/selectors";
import { Keyword } from "../../../KeywordsTable/types";

type Props = {
  searchId: Search.Data["id"];
  keywords: Search.Keyword[];
  filteredKeywords: Search.Keyword[];
  duplicatedKeywords: Search.Keyword[];
  selectedKeywords: Record<Keyword, true>;
  isKeywordsLoading: boolean;
  isKeywordsPending: boolean;
  isKeywordsExactMatch: boolean;
};

export const KeywordsInfo: FC<Props> = ({
  searchId,
  keywords,
  filteredKeywords,
  selectedKeywords,
  isKeywordsLoading,
  isKeywordsPending,
  duplicatedKeywords,
  isKeywordsExactMatch,
}) => {
  const { t } = useTranslation();

  const search = useSelector((state: Store.RootState) =>
    selectSearchById(state, searchId),
  );

  const location = useSelector((state: Store.RootState) =>
    selectLocationById(state, search?.locationId || ""),
  );

  const language = useSelector((state: Store.RootState) =>
    selectLanguageById(state, search?.languageId || ""),
  );

  const searchSubject = useMemo<string>(
    () => search?.subject || "",
    [search?.subject],
  );

  const locationName = useMemo<string>(
    () => location?.name || "",
    [location?.name],
  );

  const languageName = useMemo<string>(
    () => language?.name || "",
    [language?.name],
  );

  const keywordsCount = useMemo<number>(
    () => filteredKeywords.length,
    [filteredKeywords.length],
  );

  const duplicatedKeywordGroupsMap = useMemo<
    Map<Search.Keyword["possibleDuplicateGroupId"], Search.Keyword[]>
  >(() => {
    const keywordsMap = new Map<
      Search.Keyword["possibleDuplicateGroupId"],
      Search.Keyword[]
    >();

    for (const keyword of duplicatedKeywords) {
      const savedDuplicatedGroupKeywords =
        keywordsMap.get(keyword.possibleDuplicateGroupId) || [];

      keywordsMap.set(keyword.possibleDuplicateGroupId, [
        ...savedDuplicatedGroupKeywords,
        keyword,
      ]);
    }

    return keywordsMap;
  }, [duplicatedKeywords]);

  const availableKeywords = useMemo<Set<Search.Keyword["string"]>>(() => {
    const _keywords = new Set<Search.Keyword["string"]>();

    for (const { string } of keywords) _keywords.add(string);

    return _keywords;
  }, [keywords]);

  const selectedKeywordsCount = useMemo<number>(() => {
    let _selectedKeywordsCount = 0;

    for (const keyword in selectedKeywords)
      if (availableKeywords.has(keyword)) _selectedKeywordsCount++;

    return _selectedKeywordsCount;
  }, [availableKeywords, selectedKeywords]);

  const selectedDuplicatesCount = useMemo<number>(() => {
    let _selectedDuplicatesCount = 0;

    for (const keywords of duplicatedKeywordGroupsMap.values()) {
      let selectedDuplicatesInGroupLength = 0;

      for (const { string } of keywords) {
        if (!selectedKeywords[string]) continue;

        selectedDuplicatesInGroupLength++;
      }

      if (selectedDuplicatesInGroupLength <= 1) continue;

      _selectedDuplicatesCount += selectedDuplicatesInGroupLength - 1;
    }

    return _selectedDuplicatesCount;
  }, [duplicatedKeywordGroupsMap, selectedKeywords]);

  const keywordsTotalVolumeLocaleNumber = useMemo<string>(() => {
    let keywordsTotalVolume = 0;

    for (const keyword of filteredKeywords)
      keywordsTotalVolume += keyword.totalVolume;

    return formatToLocaleNumber(keywordsTotalVolume);
  }, [filteredKeywords]);

  const selectedKeywordsTotalVolumeLocaleNumber = useMemo<string>(() => {
    let selectedKeywordsTotalVolume = 0;

    for (const keyword of keywords) {
      const isKeywordSelected = selectedKeywords[keyword.string];

      if (isKeywordSelected) selectedKeywordsTotalVolume += keyword.totalVolume;
    }

    return formatToLocaleNumber(selectedKeywordsTotalVolume);
  }, [keywords, selectedKeywords]);

  const isSelectedDuplicatesShown = useMemo<boolean>(
    () => Boolean(selectedDuplicatesCount),
    [selectedDuplicatesCount],
  );

  const isSelectionShown = useMemo<boolean>(
    () => !isKeywordsPending && !isKeywordsLoading,
    [isKeywordsPending, isKeywordsLoading],
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleWrapper}>
        <span className={styles.title}>
          {t("component.keywords_table.label.keywords_info")}
        </span>
        <span className={styles.subtitle}>
          {t("component.keywords_table.label.keywords_instruction")}
        </span>
      </div>
      <div className={styles.searchWrapper}>
        <span>{t("component.keywords_table.label.search")}</span>
        <span title={searchSubject}>{searchSubject}</span>
      </div>
      {locationName && (
        <div className={styles.locationWrapper}>
          <span>{t("component.keywords_table.label.location")}</span>
          <span title={locationName}>{locationName}</span>
        </div>
      )}
      {languageName && (
        <div className={styles.languageWrapper}>
          <span>{t("component.keywords_table.label.language")}</span>
          <span title={languageName}>{languageName}</span>
        </div>
      )}
      {isSelectionShown && (
        <>
          <div className={styles.selectedKeywordsWrapper}>
            <span>{t("component.keywords_table.label.selected_keywords")}</span>
            <span>
              <Translation
                i18nKey="component.keywords_table.label.keywords_count"
                values={{ count: selectedKeywordsCount }}
              />
            </span>
            {isSelectedDuplicatesShown && (
              <span>
                <Translation
                  i18nKey="component.keywords_table.label.duplicates_count"
                  values={{ count: selectedDuplicatesCount }}
                />
              </span>
            )}
            <span>
              <Translation
                i18nKey="component.keywords_table.label.search_volume"
                values={{ count: selectedKeywordsTotalVolumeLocaleNumber }}
              />
            </span>
          </div>
          <div className={styles.totalKeywordsWrapper}>
            <span className={styles.title}>
              {isKeywordsExactMatch
                ? t("component.keywords_table.label.total_exact_match_keywords")
                : t("component.keywords_table.label.total_keywords")}
            </span>
            <span>
              <Translation
                i18nKey="component.keywords_table.label.keywords_count"
                values={{ count: keywordsCount }}
              />
            </span>
            <span>
              <Translation
                i18nKey="component.keywords_table.label.search_volume"
                values={{ count: keywordsTotalVolumeLocaleNumber }}
              />
            </span>
          </div>
        </>
      )}
    </div>
  );
};
