import { FC, useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import styles from "./App.module.scss";
import Context from "src/context";
import { ROUTES } from "src/constants";
import { ModalPortal, PreloaderPortal, ToastNotification } from "src/features";

import Auth from "src/containers/Auth/Auth";
import Home from "src/containers/Home/Home";
import Share from "src/containers/Share/Share";

// Inner imports
import {
  usePathReplace,
  useWindowHeight,
  useLocalizationLoader,
  useVersionInfoObserver,
} from "./hooks";

const App: FC = () => {
  const { isUserAuthenticated, isAuthenticationObserverSet } =
    useContext(Context);

  usePathReplace();

  useWindowHeight();

  useLocalizationLoader();

  useVersionInfoObserver();

  if (!isAuthenticationObserverSet) return null;

  return (
    <>
      <div className={styles.app}>
        <div className={styles.content}>
          <div className={styles.section}>
            <Switch>
              <Route path={ROUTES.share}>
                <Share />
              </Route>
              <Route path="/">
                {isUserAuthenticated ? <Home /> : <Auth />}
              </Route>
              <Redirect to="/" />
            </Switch>
          </div>
        </div>
      </div>
      <ModalPortal />
      <PreloaderPortal />
      <ToastNotification />
    </>
  );
};

export default App;
