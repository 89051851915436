import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useAbortController } from "src/hooks";
import { getSuggestedCategories } from "src/store/trackers/trackersApi";
import {
  showDevelopmentError,
  showToastNotification,
  isAbortedRequestErrorTypeGuard,
} from "src/utils";

type Props = {
  status: LoadingStatus;
  updateStatusHandler: (status: LoadingStatus) => void;
};

export const useGetSuggestedCategories = ({
  status,
  updateStatusHandler,
}: Props) => {
  const { t } = useTranslation();

  const abortController = useAbortController();

  const getDefaultCategory = useCallback(
    (query: string): Tracker.CategoryEntity => ({
      dashboardName: query,
      dashboardDescription: "",
    }),
    [],
  );

  const _getSuggestedCategories = async ({
    query,
    callback,
  }: {
    query: string;
    callback?: (categories: Tracker.CategoryEntity[]) => void;
  }): Promise<Tracker.CategoryEntity[]> => {
    try {
      updateStatusHandler("loading");

      const result = await getSuggestedCategories(
        { query },
        { signal: abortController?.signal },
      );

      if (!result.length && query) result.push(getDefaultCategory(query));

      callback?.(result);

      updateStatusHandler("succeeded");

      return result;
    } catch (error) {
      if (isAbortedRequestErrorTypeGuard(error)) return [];

      showDevelopmentError({ error });

      showToastNotification({
        type: "error",
        text: t("common.error.server_error"),
      });

      updateStatusHandler("failed");

      const result: Tracker.CategoryEntity[] = [];

      if (!result.length && query) result.push(getDefaultCategory(query));

      callback?.(result);

      return result;
    }
  };

  const cancelGetSuggestedCategories = (): void => {
    if (abortController.signal.aborted) return;

    return abortController.abort();
  };

  return {
    loadingStatus: status,
    cancelGetSuggestedCategories,
    getSuggestedCategories: _getSuggestedCategories,
  };
};
