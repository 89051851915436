import { useEffect } from "react";

type Props = {
  key?: string;
  callback: () => void;
  type: Extract<keyof DocumentEventMap, "keyup" | "keydown">;
};

export const useKeyboardListener = ({ type, key, callback }: Props) => {
  useEffect(() => {
    const keyboardListener = (event: KeyboardEvent): void => {
      if (!key) callback();

      if (event.key === key) callback();
    };

    document.addEventListener(type, keyboardListener);

    return () => document.removeEventListener(type, keyboardListener);
  }, [callback, key, type]);
};
