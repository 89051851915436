import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { usePreviousState } from "src/hooks";
import {
  selectLanguagesByKeywordsDataSource,
  selectLocationsByKeywordsDataSource,
} from "src/store/selectors";
import {
  DEFAULT_LANGUAGE_ID,
  SEARCH_DEFAULT_KEYWORDS_DATA_SOURCE,
} from "../constants";
import { getLocationDefaultLanguageId } from "../utils";

export const useLanguageId = ({
  locationId = "",
  languageId: initialLanguageId = "",
  keywordsDataSource = SEARCH_DEFAULT_KEYWORDS_DATA_SOURCE,
}: {
  locationId?: Location.Data["id"];
  languageId?: Language.Data["id"];
  keywordsDataSource?: Search.KeywordsDataSource;
}): [Location.Data["id"], Dispatch<SetStateAction<Location.Data["id"]>>] => {
  const locations = useSelector((state: Store.RootState) =>
    selectLocationsByKeywordsDataSource(state, keywordsDataSource),
  );

  const languages = useSelector((state: Store.RootState) =>
    selectLanguagesByKeywordsDataSource(state, keywordsDataSource),
  );

  const previousLocationId = usePreviousState(locationId);

  const [languageId, setLanguageId] =
    useState<Language.Data["id"]>(initialLanguageId);

  useEffect(() => {
    if (languageId) return;

    const newLanguageId = getLocationDefaultLanguageId(
      locationId,
      locations,
      languages,
    );

    setLanguageId(newLanguageId);
  }, [languageId, languages, locationId, locations]);

  useEffect(() => {
    if (!previousLocationId || previousLocationId === locationId) return;

    const newLanguageId = getLocationDefaultLanguageId(
      locationId,
      locations,
      languages,
    );

    setLanguageId(newLanguageId);
  }, [languages, locationId, locations, previousLocationId]);

  useEffect(() => {
    if (initialLanguageId) setLanguageId(initialLanguageId);
  }, [initialLanguageId]);

  useEffect(() => {
    const isLanguageAvailable = languages.some(({ id }) => id === languageId);

    if (!isLanguageAvailable) setLanguageId(DEFAULT_LANGUAGE_ID);
  }, [languages, languageId]);

  return [languageId, setLanguageId];
};
