import { FC, memo } from "react";
import { useTranslation } from "react-i18next";

import styles from "./Footer.module.scss";
import { WebsiteLogoVertical } from "src/assets/icons";

export const Footer: FC = memo(() => {
  const { t } = useTranslation();

  return (
    <footer className={styles.footerWrapper}>
      <div className={styles.footerLogoWrapper}>
        <div className={styles.footerLogo}>
          <WebsiteLogoVertical />
        </div>
        <span className={styles.footerLogoHeader}>
          {t("component.footer.label.powered_by")}
        </span>
      </div>
    </footer>
  );
});
