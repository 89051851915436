export const SEARCH_STATUSES = [
  "PENDING",
  "NO_SELECTED_KEYWORDS",
  "READY",
] as const;

export const SEARCH_DEFAULT_STATUS: Search.Status = "PENDING";

export const SEARCH_DEFAULT_DESCRIPTION: Search.CreationData["description"] =
  "";

export const SEARCH_UPDATED_PROPERTIES_TO_RECREATE: (keyof Search.CreationData)[] =
  ["description", "locationId", "languageId", "subject"];

export const SEARCH_INPUT_LIMIT: number = 150;

export const SEARCH_NAME_INPUT_LIMIT: number = 80;

export const SEARCH_KEYWORDS_DATA_SOURCES = [
  "google",
  "tiktok",
  "amazon",
  "youtube",
  "instagram",
  "bing",
  "ebay",
  "pinterest",
  "twitter",
  "etsy",
  "app-store",
  "play-store",
] as const;

export const SEARCH_DEFAULT_KEYWORDS_DATA_SOURCE: Search.KeywordsDataSource =
  "google";

export const SEARCH_KEYWORDS_DATA_SOURCE_MAP: Record<
  Search.KeywordsDataSource,
  { labelKey: string; icon: AppIcon }
> = {
  bing: { labelKey: "search.label.keywords_data_source_bing", icon: "Bing" },
  ebay: { labelKey: "search.label.keywords_data_source_ebay", icon: "Ebay" },
  etsy: { labelKey: "search.label.keywords_data_source_etsy", icon: "Etsy" },
  google: {
    labelKey: "search.label.keywords_data_source_google",
    icon: "Google",
  },
  tiktok: {
    labelKey: "search.label.keywords_data_source_tiktok",
    icon: "Tiktok",
  },
  amazon: {
    labelKey: "search.label.keywords_data_source_amazon",
    icon: "Amazon",
  },
  youtube: {
    labelKey: "search.label.keywords_data_source_youtube",
    icon: "Youtube",
  },
  twitter: {
    labelKey: "search.label.keywords_data_source_twitter",
    icon: "Twitter",
  },
  pinterest: {
    labelKey: "search.label.keywords_data_source_pinterest",
    icon: "Pinterest",
  },
  instagram: {
    labelKey: "search.label.keywords_data_source_instagram",
    icon: "Instagram",
  },
  "app-store": {
    labelKey: "search.label.keywords_data_source_app_store",
    icon: "AppStore",
  },
  "play-store": {
    labelKey: "search.label.keywords_data_source_play_store",
    icon: "PlayStore",
  },
};
