import {
  useMemo,
  forwardRef,
  ChangeEvent,
  ForwardedRef,
  KeyboardEvent,
  InputHTMLAttributes,
} from "react";
import cx from "classnames";
import isString from "lodash/isString";
import { useTranslation } from "react-i18next";

import styles from "./Textarea.module.scss";
import { limitString } from "src/utils";

type Props = {
  title?: string;
  className?: string;
  wordLimit?: number;
  isLimitShown?: boolean;
  characterLimit?: number;
  textareaClassName?: string;
  isCharacterLimitShown?: boolean;
  changeHandler?: (value: string) => void;
} & Omit<InputHTMLAttributes<HTMLTextAreaElement>, "className">;

export const Textarea = forwardRef(
  (
    {
      title,
      value,
      wordLimit,
      className,
      changeHandler,
      characterLimit,
      textareaClassName,
      isLimitShown = true,
      ...rest
    }: Props,
    ref: ForwardedRef<HTMLTextAreaElement>,
  ) => {
    const { t } = useTranslation();

    const valueWords = useMemo<string[]>(() => {
      if (!value || !isString(value)) return [];

      return value.split(" ").filter(Boolean);
    }, [value]);

    const hasCharacterLimit = useMemo<boolean>(
      () => Boolean(isLimitShown && characterLimit && isString(value)),
      [isLimitShown, characterLimit, value],
    );

    const hasWordLimit = useMemo<boolean>(
      () => Boolean(isLimitShown && wordLimit && isString(value)),
      [isLimitShown, wordLimit, value],
    );

    const characterLimitText = useMemo<string>(() => {
      if (!characterLimit || !isString(value)) return "";

      return t("component.textarea.label.limit", {
        length: value.length,
        limit: characterLimit,
      });
    }, [characterLimit, value, t]);

    const wordLimitText = useMemo<string>(() => {
      if (!wordLimit || !isString(value)) return "";

      return t("component.input.label.limit", {
        limit: wordLimit,
        length: valueWords.length,
      });
    }, [wordLimit, value, t, valueWords.length]);

    const onKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>): void => {
      if (event.key === "Enter" && !event.shiftKey) event.preventDefault();
    };

    const onKeyUp = (event: KeyboardEvent<HTMLTextAreaElement>): void => {
      if (event.key !== "Enter" || event.shiftKey) return;

      const element = event.target as HTMLTextAreaElement;

      const formElement = element.closest("form");

      if (formElement) formElement.requestSubmit();
    };

    const onChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
      const formattedValue = limitString(event.target.value, {
        wordLimit,
        characterLimit,
      });

      changeHandler?.(formattedValue);
    };

    return (
      <div className={cx(styles.wrapper, className)}>
        <textarea
          ref={ref}
          value={value}
          title={title}
          onKeyUp={onKeyUp}
          onChange={onChange}
          onKeyDown={onKeyDown}
          className={cx(styles.textarea, textareaClassName)}
          {...rest}
        />
        <div className={styles.limitWrapper}>
          {hasCharacterLimit && (
            <div className={styles.limit}>
              <span>{characterLimitText}</span>
            </div>
          )}
          {hasWordLimit && (
            <div className={styles.limit}>
              <span>{wordLimitText}</span>
            </div>
          )}
        </div>
      </div>
    );
  },
);
