import { FC, memo, useCallback, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import styles from "./DashboardHead.module.scss";
import { AddTrackerToCompare } from "src/features";
import { useLocationState, useModal } from "src/hooks";
import { DashboardPageContext } from "src/pages/Dashboards/DashboardPageWrapper/context";
import {
  selectDashboardById,
  selectIsCompanyDashboard,
} from "src/store/selectors";

// Inner imports
import { DashboardInfo, DashboardActionButtons } from "./components";

type Props = {
  isReadOnly: boolean;
  trackersCollectionId: TrackersCollection.Data["id"];
  dashboardDateRangeId: DashboardDateRange.Data["id"];
  setExpandedWidgetId: (value: Widget.IdType | null) => void;
};

export const DashboardHead: FC<Props> = memo(
  ({
    isReadOnly,
    setExpandedWidgetId,
    trackersCollectionId,
    dashboardDateRangeId,
  }) => {
    const history = useHistory();

    const { locationState } = useLocationState();

    const { closeModal } = useModal();

    const { setSidebarContent, closeSidebar } =
      useContext(DashboardPageContext);

    const dashboard = useSelector((state: Store.RootState) =>
      selectDashboardById(state, trackersCollectionId),
    );

    const isCompanyDashboard = useSelector((state: Store.RootState) =>
      selectIsCompanyDashboard(state, trackersCollectionId),
    );

    const onAddTrackerSubmit = useCallback((): void => {
      closeSidebar();

      closeModal();
    }, [closeModal, closeSidebar]);

    useEffect(() => {
      if (!isCompanyDashboard) return;

      const { isAddCompareSidebarOpen, ...cleanLocationState } = locationState;

      if (!isAddCompareSidebarOpen) return;

      history.replace({ state: cleanLocationState });

      setSidebarContent(
        <AddTrackerToCompare
          submitHandler={onAddTrackerSubmit}
          trackersCollectionId={trackersCollectionId}
        />,
      );
    }, [
      history,
      locationState,
      setSidebarContent,
      isCompanyDashboard,
      onAddTrackerSubmit,
      trackersCollectionId,
    ]);

    if (!dashboard) return null;

    return (
      <div className={styles.wrapper}>
        <div className={styles.headWrapper}>
          <div className={styles.nameWrapper}>
            <span title={dashboard?.name} className={styles.name}>
              {dashboard?.name}
            </span>
          </div>
          <DashboardActionButtons
            isReadOnly={isReadOnly}
            trackersCollectionId={trackersCollectionId}
            dashboardDateRangeId={dashboardDateRangeId}
            setExpandedWidgetIdHandler={setExpandedWidgetId}
          />
        </div>
        <div className={styles.infoWrapper}>
          <DashboardInfo
            isReadOnly={isReadOnly}
            trackersCollectionId={trackersCollectionId}
            dashboardDateRangeId={dashboardDateRangeId}
          />
        </div>
      </div>
    );
  },
);
