import { FC, useCallback, useEffect, useMemo, useState } from "react";
import cx from "classnames";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./SelectedSearch.module.scss";
import { useModal } from "src/hooks";
import { useAppDispatch } from "src/store";
import { getSearchKeywords } from "src/store/searches/searchesApi";
import { Button, ExternalLink, Preloader, Tooltip } from "src/components";
import { createSearch, fetchSearchConfigurationById } from "src/store/actions";
import {
  EditSearchModal,
  DuplicateSearchModal,
  SearchKeywordsDataSourceIcon,
} from "src/features";
import {
  selectLocationById,
  selectLanguageById,
  selectSearchConfigurationById,
} from "src/store/selectors";
import {
  getKeywordSearchLink,
  showToastNotification,
  getElementContrastedColor,
  isSearchCreatedTypeGuard,
} from "src/utils";
import {
  Sync,
  Edit,
  ImageSearch,
  GearOutline,
  DuplicateOutline,
  MinusCircleOutline,
  Search as SearchIcon,
} from "src/assets/icons";

// Inner imports
import { SelectedSearchKeywordsInfo } from "../../components";
import { useGetSearchDescription, useSearchStatusObserver } from "../../hooks";

type Props = {
  keywordsData?: Search.KeywordsData;
  search: Search.Data | Search.CreationData;
  saveSearchHandler: (search: Search.Data) => void;
  openKeywordsHandler: (search: Search.Data) => void;
  duplicateSearchHandler: (search: Search.CreationData) => void;
  unselectSearchHandler: (search: Search.CreationData) => void;
  updateSearchHandler: ({
    id,
    changes,
  }: {
    id: Search.Data["id"];
    changes: Search.CreationData;
  }) => void;
  updateSearchKeywordsHandler: (
    searchId: Search.Data["id"],
    keywords: Search.Keyword[],
    status: LoadingStatus,
  ) => void;
};

export const SelectedSearch: FC<Props> = ({
  search,
  keywordsData,
  saveSearchHandler,
  openKeywordsHandler,
  updateSearchHandler,
  unselectSearchHandler,
  duplicateSearchHandler,
  updateSearchKeywordsHandler,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { setModal } = useModal();

  const searchId = useMemo<Search.Data["id"]>(() => search.id, [search]);

  const location = useSelector((state: Store.RootState) =>
    selectLocationById(state, search.locationId),
  );

  const language = useSelector((state: Store.RootState) =>
    selectLanguageById(state, search.languageId),
  );

  const searchConfiguration = useSelector((state: Store.RootState) =>
    selectSearchConfigurationById(state, searchId),
  );

  const [searchCreateLoadingStatus, setSearchCreateLoadingStatus] =
    useState<LoadingStatus>("idle");

  const { loadingStatus: getDescriptionLoadingStatus, getSearchDescription } =
    useGetSearchDescription(search, location);

  const { searchStatus } = useSearchStatusObserver(search);

  const locationName = useMemo<Location.Data["name"]>(
    () => location?.name || "",
    [location?.name],
  );

  const languageName = useMemo<Language.Data["name"]>(
    () => language?.name || "",
    [language?.name],
  );

  const isSearchCreateLoading = useMemo<boolean>(
    () => searchCreateLoadingStatus === "loading",
    [searchCreateLoadingStatus],
  );

  const keywords = useMemo<Search.Keyword[]>(
    () => keywordsData?.keywords || [],
    [keywordsData?.keywords],
  );

  const keywordsStatus = useMemo<LoadingStatus>(
    () => keywordsData?.status || "idle",
    [keywordsData?.status],
  );

  const isUpdateDescriptionLoading = useMemo<boolean>(
    () => getDescriptionLoadingStatus === "loading",
    [getDescriptionLoadingStatus],
  );

  const isKeywordsWarning = useMemo<boolean>(() => {
    if (keywordsStatus === "loading" || keywordsStatus === "idle") return false;

    return searchStatus === "NO_SELECTED_KEYWORDS";
  }, [keywordsStatus, searchStatus]);

  const description = useMemo<Search.Data["description"]>(
    () => search.description || "",
    [search.description],
  );

  const { searchLink, imageSearchLink } = useMemo(
    () => ({
      searchLink: getKeywordSearchLink({ keyword: search.subject, location }),
      imageSearchLink: getKeywordSearchLink({
        keyword: search.subject,
        location,
        type: "image",
      }),
    }),
    [search.subject, location],
  );

  const headerRefCallback = useCallback(
    (element: HTMLDivElement | null): void => {
      if (!element) return;

      element.style.color = getElementContrastedColor({ element });
    },
    [],
  );

  useEffect(() => {
    if (
      !searchStatus ||
      !isSearchCreatedTypeGuard(search) ||
      search.status === searchStatus
    )
      return;

    updateSearchHandler({
      id: search.id,
      changes: { ...search, status: searchStatus },
    });
  }, [search, searchStatus, updateSearchHandler]);

  useEffect(() => {
    if (
      searchCreateLoadingStatus !== "succeeded" &&
      isSearchCreatedTypeGuard(search)
    )
      setSearchCreateLoadingStatus("succeeded");
  }, [search, searchCreateLoadingStatus]);

  useEffect(() => {
    if (
      searchCreateLoadingStatus !== "idle" ||
      isSearchCreatedTypeGuard(search)
    )
      return;

    setSearchCreateLoadingStatus("loading");

    dispatch(createSearch(search))
      .unwrap()
      .then((newSearch) => {
        updateSearchKeywordsHandler(newSearch.id, [], "idle");

        updateSearchHandler({ id: search.id, changes: newSearch });

        setSearchCreateLoadingStatus("succeeded");
      })
      .catch((error) => {
        console.error(error);

        showToastNotification({
          type: "error",
          text: t("common.error.server_error"),
        });

        setSearchCreateLoadingStatus("failed");

        unselectSearchHandler(search);
      });
  }, [
    t,
    search,
    dispatch,
    saveSearchHandler,
    updateSearchHandler,
    unselectSearchHandler,
    searchCreateLoadingStatus,
    updateSearchKeywordsHandler,
  ]);

  useEffect(() => {
    if (
      searchConfiguration ||
      searchCreateLoadingStatus !== "succeeded" ||
      !isSearchCreatedTypeGuard(search)
    )
      return;

    if (searchStatus !== "READY" && searchStatus !== "NO_SELECTED_KEYWORDS")
      return;

    dispatch(fetchSearchConfigurationById(search.id))
      .unwrap()
      .catch((error) => {
        console.error(error);

        showToastNotification({
          type: "error",
          text: t("common.error.server_error"),
        });
      });
  }, [
    t,
    search,
    dispatch,
    searchStatus,
    searchConfiguration,
    searchCreateLoadingStatus,
  ]);

  useEffect(() => {
    if (
      !searchConfiguration ||
      keywordsStatus !== "idle" ||
      !isSearchCreatedTypeGuard(search)
    )
      return;

    if (searchStatus !== "READY" && searchStatus !== "NO_SELECTED_KEYWORDS")
      return;

    updateSearchKeywordsHandler(search.id, [], "loading");

    getSearchKeywords(search.id)
      .then((keywords) =>
        updateSearchKeywordsHandler(search.id, keywords, "succeeded"),
      )
      .catch(() => {
        updateSearchKeywordsHandler(search.id, [], "failed");

        showToastNotification({
          type: "error",
          text: t("common.error.server_error"),
        });
      });
  }, [
    t,
    search,
    searchStatus,
    keywordsStatus,
    searchConfiguration,
    searchCreateLoadingStatus,
    updateSearchKeywordsHandler,
  ]);

  const onDescriptionUpdate = async (): Promise<void> => {
    if (!isSearchCreatedTypeGuard(search)) return;

    const newDescription = await getSearchDescription();

    if (!newDescription) return;

    updateSearchHandler({
      id: search.id,
      changes: { ...search, description: newDescription },
    });
  };

  const onEditClick = (): void => {
    if (!isSearchCreatedTypeGuard(search)) return;

    setModal(
      <EditSearchModal search={search} submitHandler={updateSearchHandler} />,
    );
  };

  const onDuplicateClick = (): void => {
    if (!isSearchCreatedTypeGuard(search)) return;

    setModal(
      <DuplicateSearchModal
        search={search}
        duplicateHandler={duplicateSearchHandler}
      />,
    );
  };

  return (
    <div
      className={cx(
        styles.selectedSearch,
        isSearchCreatedTypeGuard(search) ? styles.active : "",
      )}
    >
      {isSearchCreateLoading && (
        <div className={styles.loaderWrapper}>
          <Preloader
            type="bar"
            text={t("component.selected_search.loader.create_search")}
          />
        </div>
      )}
      <div
        className={cx(styles.header, isKeywordsWarning ? styles.warning : "")}
        ref={headerRefCallback}
      >
        <div className={styles.title}>
          <Tooltip content={search.subject} className={styles.searchName}>
            <span>{search.subject}</span>
          </Tooltip>
          {isSearchCreatedTypeGuard(search) && (
            <Tooltip
              content={t("component.selected_search.tooltip.edit_search")}
            >
              <Button
                buttonSize="small"
                onClick={onEditClick}
                buttonStyle="transparent"
              >
                <Edit />
              </Button>
            </Tooltip>
          )}
        </div>
        {isSearchCreatedTypeGuard(search) && (
          <div className={styles.actions}>
            <Tooltip
              content={t("component.selected_search.tooltip.search_link")}
            >
              <Button buttonSize="small" buttonStyle="transparent">
                <ExternalLink href={searchLink}>
                  <SearchIcon />
                </ExternalLink>
              </Button>
            </Tooltip>
            <Tooltip
              content={t("component.selected_search.tooltip.image_search_link")}
            >
              <Button buttonSize="small" buttonStyle="transparent">
                <ExternalLink href={imageSearchLink}>
                  <ImageSearch />
                </ExternalLink>
              </Button>
            </Tooltip>
            <Tooltip
              content={t("component.selected_search.tooltip.keyword_selection")}
            >
              <Button
                buttonSize="small"
                buttonStyle="transparent"
                className={styles.settingsButton}
                onClick={() => openKeywordsHandler(search)}
              >
                <GearOutline />
              </Button>
            </Tooltip>
          </div>
        )}
      </div>
      <hr className={styles.divider} />
      <div className={styles.content}>
        <span className={styles.description} title={description}>
          {description}
        </span>
        <SelectedSearchKeywordsInfo
          search={search}
          keywords={keywords}
          searchStatus={searchStatus}
          keywordsStatus={keywordsStatus}
          openKeywordsHandler={openKeywordsHandler}
        />
      </div>
      <div className={styles.footer}>
        <div className={styles.settings}>
          <div
            title={search.keywordsDataSource}
            className={styles.keywordsDataSource}
          >
            <SearchKeywordsDataSourceIcon
              keywordsDataSource={search.keywordsDataSource}
            />
          </div>
          <div className={styles.location} title={locationName}>
            <span>{locationName}</span>
          </div>
          <div className={styles.language} title={languageName}>
            <span>{search.languageId.toUpperCase()}</span>
          </div>
        </div>
        {isSearchCreatedTypeGuard(search) && (
          <div className={styles.actions}>
            <Tooltip
              content={t("component.selected_search.tooltip.copy_search")}
            >
              <Button
                buttonStyle="transparent"
                buttonSize="small"
                onClick={onDuplicateClick}
              >
                <DuplicateOutline />
              </Button>
            </Tooltip>
            <Tooltip
              className={cx(
                styles.updateButton,
                isUpdateDescriptionLoading ? styles.updateButtonLoading : "",
              )}
              content={t(
                "component.selected_search.tooltip.update_description",
              )}
            >
              <Button
                onClick={onDescriptionUpdate}
                buttonStyle="transparent"
                buttonSize="small"
                disabled={isUpdateDescriptionLoading}
              >
                <Sync />
              </Button>
            </Tooltip>
            <Tooltip
              content={t("component.selected_search.tooltip.remove_search")}
            >
              <Button
                className={styles.unselectSearchButton}
                onClick={() => unselectSearchHandler(search)}
                buttonStyle="transparent"
                buttonSize="small"
              >
                <MinusCircleOutline />
              </Button>
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
};
