import { useRef, RefObject, useCallback } from "react";

import { isInputElementTypeGuard } from "src/utils";

export const useElementFocus = <T extends HTMLElement>(): [
  RefObject<T>,
  () => void,
] => {
  const ref = useRef<T>(null);

  const setFocus = useCallback((): void => {
    const element = ref.current;

    if (!element) return;

    element.focus();

    if (!isInputElementTypeGuard(element)) return;

    const cursorEndPosition = element.value.length;

    element.setSelectionRange(cursorEndPosition, cursorEndPosition);
  }, []);

  return [ref, setFocus];
};
