import { FC, useMemo } from "react";

import styles from "./VolumeShareTableChartTotalLabel.module.scss";
import { Translation } from "src/components";
import { calculateValueChangeStyle } from "src/utils";
import { renderTrendChangeColumn } from "../../utils";

type Props = {
  data: (Widgets.VolumeShare.TableChartValue &
    Pick<Tracker.Data, "id" | "name">)[];
  valueFormatter?: (value: number) => string;
};

export const VolumeShareTableChartTotalLabel: FC<Props> = ({
  data,
  valueFormatter,
}) => {
  const [totalValue, totalAdditionalValue] = useMemo<[number, number]>(() => {
    let [totalValue, totalAdditionalValue] = [0, 0];

    for (const { volume, trendChange } of data) {
      totalValue += volume;
      totalAdditionalValue += trendChange;
    }

    return [totalValue, totalAdditionalValue];
  }, [data]);

  const formattedTotalValue = useMemo<string | number>(
    () => valueFormatter?.(totalValue) || totalValue,
    [totalValue, valueFormatter],
  );

  return (
    <div className={styles.wrapper}>
      <span>
        <Translation
          i18nKey="widget.volume_share.table.label.total"
          values={{ value: formattedTotalValue }}
          components={{
            additionalValue: (
              <span style={calculateValueChangeStyle(totalAdditionalValue)}>
                {renderTrendChangeColumn(totalAdditionalValue)}
              </span>
            ),
          }}
        />
      </span>
    </div>
  );
};
