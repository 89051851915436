import { FC, FormEvent, useMemo, useState } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";

import styles from "./AddNewTrackerEntityForm.module.scss";
import { Button, Input } from "src/components";
import { removeExtraSpaces } from "src/utils";
import { useTemporaryErrors } from "src/hooks";
import { ChevronDown, Plus } from "src/assets/icons";

type Props = {
  value: string;
  className?: string;
  isExpanded?: boolean;
  characterLimit?: number;
  isSubmitDisabled?: boolean;
  submitHandler: (value: string) => void;
  changeValueHandler: (value: string) => void;
};

export const AddNewTrackerEntityForm: FC<Props> = ({
  value,
  className = "",
  submitHandler,
  characterLimit,
  changeValueHandler,
  isExpanded: defaultIsExpanded = false,
  isSubmitDisabled: defaultIsSubmitDisabled = false,
}) => {
  const { t } = useTranslation();

  const { errors, setErrors } = useTemporaryErrors(3000);

  const [isExpanded, setIsExpanded] = useState<boolean>(defaultIsExpanded);

  const isSubmitDisabled = useMemo<boolean>(
    () => defaultIsSubmitDisabled || !value,
    [defaultIsSubmitDisabled, value],
  );

  const onExpandClick = (): void => setIsExpanded(true);

  const onSubmitHandler = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    const name = removeExtraSpaces(value);

    if (!name)
      return setErrors({
        name: t(
          "page.create_tracker.configuration.form.validation.name_required",
        ),
      });

    submitHandler(value);
  };

  if (!isExpanded)
    return (
      <div className={cx(styles.wrapper, className)}>
        <div className={styles.expandButtonWrapper}>
          <Button
            buttonSize="small"
            onClick={onExpandClick}
            buttonStyle="transparent"
            className={styles.expandButton}
          >
            <span>
              {t(
                "page.create_tracker.configuration.button.expand_suggested_entity",
              )}
            </span>
            <ChevronDown />
          </Button>
        </div>
      </div>
    );

  return (
    <div className={cx(styles.wrapper, className)}>
      <div className={styles.formWrapper}>
        <form className={styles.form} onSubmit={onSubmitHandler}>
          <Input
            autoFocus
            value={value}
            inputClassName={styles.input}
            characterLimit={characterLimit}
            className={styles.inputWrapper}
            changeHandler={changeValueHandler}
          />
          <Button
            type="submit"
            buttonSize="small"
            disabled={isSubmitDisabled}
            className={styles.submitButton}
          >
            <Plus />
          </Button>
          {errors["name"] && (
            <div className={styles.error}>{errors["name"]}</div>
          )}
        </form>
      </div>
    </div>
  );
};
