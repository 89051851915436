import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useAbortController } from "src/hooks";
import { DEFAULT_TRACKER_CATEGORY } from "src/constants";
import { getSuggestedSearches } from "src/store/searches/searchesApi";
import {
  selectLanguagesEntities,
  selectLocationsEntities,
} from "src/store/selectors";
import {
  showDevelopmentError,
  showToastNotification,
  isAbortedRequestErrorTypeGuard,
} from "src/utils";

const SUGGESTED_SEARCHES_LIMIT_PER_REQUEST = 8 as const;

type Props = {
  suggestedSearchesStatus: LoadingStatus;
  keywordsDataSource: Search.KeywordsDataSource;
  updateSuggestedSearchesStatusHandler: (status: LoadingStatus) => void;
  updateExcludedSearchesHandler: (searches: Search.CreationData[]) => void;
};

export const useGetSuggestedSearches = ({
  keywordsDataSource,
  suggestedSearchesStatus,
  updateExcludedSearchesHandler,
  updateSuggestedSearchesStatusHandler,
}: Props) => {
  const { t } = useTranslation();

  const abortController = useAbortController();

  const locations = useSelector(selectLocationsEntities);

  const languages = useSelector(selectLanguagesEntities);

  const isLoading = useMemo<boolean>(
    () => suggestedSearchesStatus === "loading",
    [suggestedSearchesStatus],
  );

  const getExcludedSubjects = useCallback(
    (searches: Search.CreationData[]): string[] => {
      const prepareSearches = searches.map(({ subject }) => subject);

      return Array.from(new Set(prepareSearches));
    },
    [],
  );

  const getLocation = useCallback(
    (locationId: Location.Data["id"]): Location.Data | undefined =>
      locations[locationId],
    [locations],
  );

  const getLanguage = useCallback(
    (languageId: Language.Data["id"]): Language.Data | undefined =>
      languages[languageId],
    [languages],
  );

  const _getSuggestedSearches = async ({
    query,
    category,
    callback,
    languageId,
    locationId,
    description,
    excludedSearches = [],
    limit = SUGGESTED_SEARCHES_LIMIT_PER_REQUEST,
  }: {
    query: string;
    limit?: number;
    locationId: Location.Data["id"];
    languageId: Language.Data["id"];
    category: Tracker.Data["category"];
    description: Tracker.Data["description"];
    excludedSearches?: Search.CreationData[];
    callback?: (searches: Search.CreationData[]) => void;
  }): Promise<Search.CreationData[]> => {
    try {
      updateSuggestedSearchesStatusHandler("loading");

      const [location, language] = [
        getLocation(locationId),
        getLanguage(languageId),
      ];

      if (!location || !language) return [];

      const result = await getSuggestedSearches(
        {
          query,
          limit,
          location,
          language,
          description,
          keywordsDataSource,
          category: category || DEFAULT_TRACKER_CATEGORY,
          excludedSubjects: getExcludedSubjects(excludedSearches),
        },
        { signal: abortController?.signal },
      );

      callback?.(result);

      updateSuggestedSearchesStatusHandler("succeeded");
      updateExcludedSearchesHandler([...excludedSearches, ...result]);

      return result;
    } catch (error) {
      if (isAbortedRequestErrorTypeGuard(error)) return [];

      showDevelopmentError({ error });

      showToastNotification({
        type: "error",
        text: t("common.error.server_error"),
      });

      updateSuggestedSearchesStatusHandler("failed");

      callback?.([]);

      return [];
    }
  };

  const cancelGetSuggestedSearches = (): void => {
    if (abortController.signal.aborted) return;

    return abortController.abort();
  };

  return {
    isLoading,
    cancelGetSuggestedSearches,
    getSuggestedSearches: _getSuggestedSearches,
  };
};
