import { FC, useMemo, useState } from "react";
import cx from "classnames";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./SuggestedTrackers.module.scss";
import { generateDocId } from "src/store/utils";
import { Button, Preloader } from "src/components";
import { getLocationDefaultLanguageId } from "src/utils";
import { ChevronDown, Stars, Sync } from "src/assets/icons";
import { DEFAULT_TRACKER_CATEGORY, TRACKER_INPUT_LIMIT } from "src/constants";
import {
  CountriesDropdown,
  LanguagesDropdown,
  AddNewTrackerEntityForm,
  KeywordsDataSourcesDropdown,
} from "src/features";
import {
  selectLanguageById,
  selectLocationById,
  selectLocationsByKeywordsDataSource,
  selectLanguagesByKeywordsDataSource,
} from "src/store/selectors";

// Inner imports
import { SuggestedTracker } from "./components";

type Props = {
  isExpanded?: boolean;
  languageId: Language.Data["id"];
  locationId: Location.Data["id"];
  suggestedTrackersStatus: LoadingStatus;
  keywordsDataSource: Search.KeywordsDataSource;
  filteredSuggestedTrackers: Tracker.CreationData[];
  selectTrackerHandler: (value: Tracker.CreationData) => void;
  updateLanguageIdHandler: (value: Language.Data["id"]) => void;
  updateLocationIdHandler: (value: Location.Data["id"]) => void;
  updateKeywordsDataSourceHandler: (value: Search.KeywordsDataSource) => void;
  updateSuggestedTrackersHandler: ({
    query,
    locationId,
    languageId,
    keywordsDataSource,
  }: {
    query?: string;
    locationId?: Location.Data["id"];
    languageId?: Language.Data["id"];
    keywordsDataSource?: Search.KeywordsDataSource;
  }) => Promise<Tracker.CreationData[]>;
};

export const SuggestedTrackers: FC<Props> = ({
  locationId,
  languageId,
  keywordsDataSource,
  selectTrackerHandler,
  suggestedTrackersStatus,
  updateLocationIdHandler,
  updateLanguageIdHandler,
  filteredSuggestedTrackers,
  updateKeywordsDataSourceHandler,
  isExpanded: defaultIsExpanded = false,
  updateSuggestedTrackersHandler,
}) => {
  const { t } = useTranslation();

  const locations = useSelector((state: Store.RootState) =>
    selectLocationsByKeywordsDataSource(state, keywordsDataSource),
  );

  const languages = useSelector((state: Store.RootState) =>
    selectLanguagesByKeywordsDataSource(state, keywordsDataSource),
  );

  const location = useSelector((state: Store.RootState) =>
    selectLocationById(state, locationId),
  );

  const language = useSelector((state: Store.RootState) =>
    selectLanguageById(state, languageId),
  );

  const [isExpanded, setIsExpanded] = useState<boolean>(defaultIsExpanded);

  const [newTrackerName, setNewTrackerName] =
    useState<Tracker.Data["name"]>("");

  const locationName = useMemo<string>(
    () => location?.name || "",
    [location?.name],
  );

  const languageName = useMemo<string>(
    () => language?.name || "",
    [language?.name],
  );

  const isLoading = useMemo<boolean>(
    () => suggestedTrackersStatus === "loading",
    [suggestedTrackersStatus],
  );

  const SuggestedTrackers = useMemo<JSX.Element>(() => {
    switch (true) {
      case Boolean(filteredSuggestedTrackers.length):
        return (
          <>
            {isLoading && (
              <div className={styles.loaderWrapper}>
                <Preloader
                  type="bar"
                  text={t(
                    "page.create_tracker.selection.loader.download_suggested_trackers",
                  )}
                />
              </div>
            )}
            {filteredSuggestedTrackers.map((tracker) => (
              <SuggestedTracker
                key={tracker.id}
                tracker={tracker}
                clickHandler={selectTrackerHandler}
              />
            ))}
          </>
        );
      case isLoading:
        return (
          <Preloader
            type="bar"
            text={t(
              "page.create_tracker.selection.loader.download_suggested_trackers",
            )}
            className={styles.preloader}
          />
        );
      default:
        return (
          <div className={styles.placeholder}>
            {t("page.create_tracker.selection.label.no_suggested_trackers")}
          </div>
        );
    }
  }, [t, isLoading, selectTrackerHandler, filteredSuggestedTrackers]);

  const onLocationIdChange = (value: Location.Data["id"]): void => {
    updateLocationIdHandler(value);

    const languageId = getLocationDefaultLanguageId(
      value,
      locations,
      languages,
    );

    updateSuggestedTrackersHandler({ locationId: value, languageId }).catch();
  };

  const onLanguageIdChange = (value: Language.Data["id"]): void => {
    updateLanguageIdHandler(value);

    updateSuggestedTrackersHandler({ languageId: value }).catch();
  };

  const onKeywordsDataSourceChange = (
    value: Search.KeywordsDataSource,
  ): void => {
    updateKeywordsDataSourceHandler(value);

    updateSuggestedTrackersHandler({ keywordsDataSource: value }).catch();
  };

  const onExpandClick = (): void => setIsExpanded(true);

  const onAddNewTracker = (value: string): void => {
    selectTrackerHandler({
      languageId,
      locationId,
      name: value,
      description: "",
      id: generateDocId(),
      category: DEFAULT_TRACKER_CATEGORY,
      keywordsDataSources: [keywordsDataSource],
    });

    setNewTrackerName("");
  };

  if (!isExpanded)
    return (
      <div className={styles.wrapper}>
        <div className={styles.expandButtonWrapper}>
          <Button
            buttonSize="small"
            onClick={onExpandClick}
            buttonStyle="transparent"
            className={styles.expandButton}
          >
            <span>{t("page.create_tracker.selection.button.add_tracker")}</span>
            <ChevronDown />
          </Button>
        </div>
      </div>
    );

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <div className={styles.titleLabel}>
          <Stars />
          <span>
            {t("page.create_tracker.selection.label.suggested_trackers")}
          </span>
        </div>
        <div className={styles.settings}>
          <div title={languageName}>
            <KeywordsDataSourcesDropdown
              isDisabled={isLoading}
              dataSource={keywordsDataSource}
              setDataSource={onKeywordsDataSourceChange}
            />
          </div>
          <div title={locationName}>
            <CountriesDropdown
              isDisabled={isLoading}
              locationId={locationId}
              setLocationId={onLocationIdChange}
              keywordsDataSource={keywordsDataSource}
            />
          </div>
          <div title={languageName}>
            <LanguagesDropdown
              isDisabled={isLoading}
              languageId={languageId}
              setLanguageId={onLanguageIdChange}
              keywordsDataSource={keywordsDataSource}
            />
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.suggestedTrackers}>{SuggestedTrackers}</div>
        <div className={styles.actions}>
          <Button
            className={cx(
              styles.actionButton,
              suggestedTrackersStatus === "loading"
                ? styles.actionButtonLoading
                : "",
            )}
            buttonSize="small"
            buttonStyle="transparent"
            disabled={suggestedTrackersStatus === "loading"}
            onClick={() => updateSuggestedTrackersHandler({})}
          >
            <Sync />
            <span>
              {t(
                "page.create_tracker.selection.button.update_suggested_trackers",
              )}
            </span>
          </Button>
        </div>
      </div>
      <AddNewTrackerEntityForm
        value={newTrackerName}
        submitHandler={onAddNewTracker}
        characterLimit={TRACKER_INPUT_LIMIT}
        changeValueHandler={setNewTrackerName}
      />
    </div>
  );
};
