import { FC, memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import cx from "classnames";

import styles from "./GeneralFormButtons.module.scss";
import { Loader, Reset } from "src/assets/icons";
import { Button } from "src/components";
import { ConfirmModal, DiscardModal } from "src/features";

// Inner imports
import type { GeneralFormButtonsProps } from "./types";
import { useModal } from "src/hooks";

export const GeneralFormButtons: FC<GeneralFormButtonsProps> = memo(
  ({
    className = "",
    isLoading,
    isButtonsActive = false,
    isCancelButtonHidden = false,
    buttonTexts = {},
    tabIndexes = {},
    onResetHandler,
  }) => {
    const history = useHistory();

    const { t } = useTranslation();

    const { setModal, closeModal } = useModal();

    const { saveButtonText, cancelButtonText, resetButtonText } = useMemo(
      () => buttonTexts,
      [buttonTexts],
    );

    const { saveButtonIndex, cancelButtonIndex, resetButtonIndex } = useMemo(
      () => tabIndexes,
      [tabIndexes],
    );

    const onCancel = () => {
      if (!isButtonsActive) return history.goBack();

      setModal(<DiscardModal />);
    };

    const onReset = (
      callback: Exclude<typeof onResetHandler, undefined>,
    ): void => {
      setModal(
        <ConfirmModal
          type="warning"
          title={t("component.modal.reset.title")}
          cancelButton={{
            text: t("component.modal.reset.button.cancel"),
            onClick: closeModal,
          }}
          acceptButton={{
            text: t("component.modal.reset.button.submit"),
            onClick: () => {
              callback();
              closeModal();
            },
          }}
        >
          <span>{t("component.modal.reset.body")}</span>
        </ConfirmModal>,
      );
    };

    return (
      <div className={cx(styles.formButtons, className)}>
        {/* Reset button */}
        {isButtonsActive && onResetHandler && !isLoading && (
          <Button
            className={styles.resetButton}
            type="button"
            buttonStyle="transparent"
            onClick={() => onReset(onResetHandler)}
            tabIndex={resetButtonIndex}
          >
            <Reset />
            <span>{resetButtonText || t("component.form.button.reset")}</span>
          </Button>
        )}

        {/* Cancel button */}
        {!isCancelButtonHidden && (
          <Button
            type="button"
            onClick={onCancel}
            buttonStyle="outlined"
            tabIndex={cancelButtonIndex}
          >
            <span>{cancelButtonText || t("component.form.button.cancel")}</span>
          </Button>
        )}

        {/* Save button */}
        <Button
          type="submit"
          buttonType="primary"
          disabled={!isButtonsActive || isLoading}
          tabIndex={saveButtonIndex}
        >
          {isLoading ? (
            <Loader className={styles.loader} />
          ) : (
            <span>{saveButtonText || t("component.form.button.submit")}</span>
          )}
        </Button>
      </div>
    );
  },
);
