import * as yup from "yup";

import {
  dashboardLayoutsSchema,
  dashboardTilesSchema,
} from "../dashboards/dashboardsSchema";

const whiteLabelDefaultDashboardSchema = yup
  .object({
    layouts: dashboardLayoutsSchema,
    tiles: dashboardTilesSchema,
  })
  .noUnknown()
  .required();

const whiteLabelFeaturesSchema = yup
  .object({
    signupEnabled: yup.boolean().required(),
  })
  .noUnknown()
  .required();

const whiteLabelLogosSchema = yup
  .object({
    big: yup.string().required(),
    medium: yup.string().required(),
    small: yup.string().required(),
    favicon: yup.string().required(),
  })
  .noUnknown()
  .required();

const whiteLabelStylesSchema = yup
  .object({
    fontFamily: yup.string().default(""),
    primaryColor: yup.string().required(),
    secondaryColor: yup.string().required(),
  })
  .noUnknown()
  .required();

export const whiteLabelSchema = yup
  .object({
    subDomainName: yup.string().required(),
    name: yup.string().required(),
    countryCode: yup.string().required(),
    explorePageTopics: yup.array(yup.string().default("")).default([]),
    promptSuggestions: yup.array(yup.string().default("")).default([]),
    features: whiteLabelFeaturesSchema,
    defaultDashboard: whiteLabelDefaultDashboardSchema,
    logos: whiteLabelLogosSchema,
    styles: whiteLabelStylesSchema,
  })
  .noUnknown()
  .required();

export const whiteLabelDomainSchema = yup
  .object({
    whiteLabelId: yup.string().required(),
  })
  .noUnknown()
  .required();
