import { FC, memo, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./CountriesDropdown.module.scss";
import { Select, Tooltip } from "src/components";
import { SelectProps } from "src/components/selectors/Select/types";
import { selectLocationsByKeywordsDataSource } from "src/store/selectors";

type Props = Pick<SelectProps, "openingDirection"> & {
  isDisabled?: boolean;
  hasDefaultStyles?: boolean;
  locationId: Location.Data["id"];
  keywordsDataSource: Search.KeywordsDataSource;
  setLocationId: (value: Location.Data["id"]) => void;
};

export const CountriesDropdown: FC<Props> = memo(
  ({
    locationId,
    setLocationId,
    keywordsDataSource,
    hasDefaultStyles = false,
    isDisabled: defaultIsDisabled = false,
    openingDirection = "bottom-end",
  }) => {
    const { t } = useTranslation();

    const locations = useSelector((state: Store.RootState) =>
      selectLocationsByKeywordsDataSource(state, keywordsDataSource),
    );

    const locationsOptions = useMemo<Option<Location.Data["id"]>[]>(
      () =>
        locations.map((location) => ({
          label: location.name,
          value: location.id,
        })),
      [locations],
    );

    const hasOneOption = useMemo<boolean>(
      () => locationsOptions.length === 1,
      [locationsOptions.length],
    );

    const isDisabled = useMemo<boolean>(
      () => defaultIsDisabled || hasOneOption,
      [defaultIsDisabled, hasOneOption],
    );

    const tooltip = useMemo<string>(() => {
      if (hasOneOption)
        return t(
          "component.countries_dropdown.tooltip.one_option.single_option",
        );

      return "";
    }, [hasOneOption, t]);

    const onLocationSelect = (value: Location.Data["id"]): void =>
      setLocationId(value);

    return (
      <div className={hasDefaultStyles ? "" : styles.wrapper}>
        <Tooltip content={tooltip}>
          <Select
            hasFilter
            value={locationId}
            isDisabled={isDisabled}
            options={locationsOptions}
            changeHandler={onLocationSelect}
            openingDirection={openingDirection}
            inputClassName={hasDefaultStyles ? "" : styles.input}
            selectClassName={hasDefaultStyles ? "" : styles.select}
            dropdownClassName={hasDefaultStyles ? "" : styles.dropdown}
          />
        </Tooltip>
      </div>
    );
  },
);
