import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Table, { SortType } from "rsuite/Table";

import "./treeTableStyles.css";
import styles from "./KeywordsTable.module.scss";
import { triggerGtmEvent } from "src/utils";
import { useDebounce, usePagination } from "src/hooks";
import { Checkbox, TablePagination } from "src/components";

// Inner imports
import type { KeywordsTableProps } from "./types";
import {
  CheckboxCell,
  EmptyTablePlaceholder,
  LineChartCell,
  SearchLinkCell,
  TableLoader,
  TotalVolumeCell,
} from "./components";
import { KEYWORDS_TABLE_STYLES, KEYWORDS_TABLE_PAGINATION } from "./constants";
import { useKeywordsTableExpand } from "./hooks";

const { HeaderCell, Column } = Table;

export const KeywordsTable: FC<KeywordsTableProps> = ({
  searchId,
  data,
  isKeywordsLoading,
  isKeywordsPending,
  sort,
  selectedKeywords,
  keywordsSelectStatus,
  isSelectAllDisabled,
  isDuplicatedKeywordsShown,
  selectAllKeywordsHandler,
  selectKeywordsHandler,
}) => {
  const { t } = useTranslation();

  const [scrollCoordinates, setScrollCoordinates] = useState<Coordinates>({
    x: 0,
    y: 0,
  });

  const {
    pageNumber,
    pagesCount,
    setPageNumber,
    slicedItems: paginatedData,
  } = usePagination({
    items: data,
    itemsPerPage: KEYWORDS_TABLE_PAGINATION.perPage,
  });

  const { expandedRowKeys, onExpandChange } = useKeywordsTableExpand({
    data,
    isDuplicatedKeywordsShown,
  });

  useEffect(() => setPageNumber(0), [data, setPageNumber]);

  const onTableSortChange = (
    dataKey: string,
    sortDirection?: SortType,
  ): void => {
    if (!sortDirection || !sort?.sortChangeHandler) return;

    sort.sortChangeHandler(dataKey, sortDirection);
  };

  const onTablePageNumberChange = (value: number): void => {
    setScrollCoordinates({ x: 0, y: 0 });
    setPageNumber(value);

    triggerGtmEvent("TrackerViewSelectKeywords", { searchId });
  };

  const onTableScroll = (x: number, y: number) =>
    setScrollCoordinates({ x, y });

  const onDebouncedTableScroll = useDebounce(onTableScroll, 500);

  return (
    <div className={styles.table}>
      <div className={styles.tableWrapper}>
        <Table
          isTree
          virtualized
          height={KEYWORDS_TABLE_STYLES.height}
          data={paginatedData as never[]}
          renderLoading={() => (
            <TableLoader
              isKeywordsLoading={isKeywordsLoading}
              isKeywordsPending={isKeywordsPending}
            />
          )}
          rowHeight={KEYWORDS_TABLE_STYLES.cellHeight}
          loading={isKeywordsLoading || isKeywordsPending}
          headerHeight={KEYWORDS_TABLE_STYLES.cellHeight}
          onSortColumn={onTableSortChange}
          rowKey="value"
          sortColumn={sort?.sortBy}
          sortType={sort?.sortDirection}
          shouldUpdateScroll={() => scrollCoordinates}
          onScroll={onDebouncedTableScroll}
          renderEmpty={() => (
            <EmptyTablePlaceholder
              text={t("component.keywords_table.label.no_keywords")}
            />
          )}
          onExpandChange={onExpandChange}
          expandedRowKeys={expandedRowKeys}
        >
          <Column
            treeCol
            align="left"
            key="value"
            flexGrow={1}
            minWidth={KEYWORDS_TABLE_STYLES.columnWidth}
          >
            <HeaderCell>
              <Checkbox
                onClick={selectAllKeywordsHandler}
                isChecked={keywordsSelectStatus === "checked"}
                isPartChecked={keywordsSelectStatus === "partial"}
                isDisabled={isSelectAllDisabled}
              />
              <div>{t("component.keywords_table.table.label.keywords")}</div>
            </HeaderCell>
            <CheckboxCell
              dataKey="value"
              isDuplicatedKeywordsShown={isDuplicatedKeywordsShown}
              selectedKeywords={selectedKeywords}
              keywordsSelectHandler={selectKeywordsHandler}
            />
          </Column>
          <Column
            width={KEYWORDS_TABLE_STYLES.columnWidth}
            align="right"
            key="totalVolume"
          >
            <HeaderCell>
              <div className={styles.totalVolumeWrapper}>
                <span>
                  {t("component.keywords_table.table.label.search_volume")}
                </span>
                {/* Needed for Carl and Intercom */}
                <div>
                  <div id="keywords-selector-tip-section" />
                </div>
              </div>
            </HeaderCell>
            <TotalVolumeCell dataKey="totalVolume" />
          </Column>
          <Column width={KEYWORDS_TABLE_STYLES.columnWidth}>
            <HeaderCell>{""}</HeaderCell>
            <LineChartCell dataKey="lineChart" />
          </Column>
          {!isDuplicatedKeywordsShown && (
            <Column
              key="searchLink"
              width={KEYWORDS_TABLE_STYLES.smallColumnWidth}
            >
              <HeaderCell>{""}</HeaderCell>
              <SearchLinkCell
                className={styles.tableLink}
                searchId={searchId}
                dataKey="value"
              />
            </Column>
          )}
          {!isDuplicatedKeywordsShown && (
            <Column
              key="searchLink"
              width={KEYWORDS_TABLE_STYLES.smallColumnWidth}
            >
              <HeaderCell>{""}</HeaderCell>
              <SearchLinkCell
                className={styles.tableLink}
                searchId={searchId}
                dataKey="value"
                searchLinkType="image"
              />
            </Column>
          )}
        </Table>
      </div>
      <div className={styles.paginationWrapper}>
        <TablePagination
          pageCount={pagesCount}
          pageRangeDisplayed={5}
          marginPagesDisplayed={1}
          forcePage={pageNumber}
          onPageChange={({ selected }) => onTablePageNumberChange(selected)}
        />
      </div>
    </div>
  );
};
