export const KEYWORDS_TABLE_STYLES = {
  height: 550,
  cellHeight: 50,
  columnWidth: 200,
  smallColumnWidth: 45,
  tooltipExtraSpace: 250,
};

export const KEYWORDS_TABLE_PAGINATION = {
  perPage: 10,
  pageLimit: 10,
  maxButtons: 5,
  size: "xs",
};
