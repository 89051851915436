import { FC, useEffect, useMemo } from "react";
import { useImmer } from "use-immer";
import { useTranslation } from "react-i18next";

import styles from "./EditTrackerConfigurationModal.module.scss";
import { withError } from "src/hocs";
import { getDifferenceInObjects } from "src/utils";
import { Form, Input, Label } from "src/components";
import { TRACKER_INPUT_LIMIT } from "src/constants";
import { useModal, useElementFocus, useTemporaryErrors } from "src/hooks";
import { ConfirmModal } from "../ConfirmModal/ConfirmModal";

const InputWithError = withError(Input);

type Props = {
  tracker: Tracker.CreationData;
  selectedTrackers?: Tracker.CreationData[];
  submitHandler: (value: Tracker.CreationData) => void;
};

export const EditTrackerConfigurationModal: FC<Props> = ({
  submitHandler,
  tracker: defaultTracker,
}) => {
  const { t } = useTranslation();

  const { closeModal } = useModal();

  const [ref, setFocus] = useElementFocus();

  const { errors, setErrors } = useTemporaryErrors(3000);

  const [tracker, setTracker] = useImmer<Tracker.CreationData>(defaultTracker);

  const isTrackersConfigurationChanged = useMemo<boolean>(
    () => Boolean(getDifferenceInObjects(tracker, defaultTracker)),
    [defaultTracker, tracker],
  );

  const isDisabled = useMemo<boolean>(
    () => !isTrackersConfigurationChanged,
    [isTrackersConfigurationChanged],
  );

  useEffect(() => setFocus(), [setFocus]);

  const onNameChange = (value: Tracker.CreationData["name"]): void =>
    setTracker((draft) => {
      draft.name = value;
    });

  const onDescriptionChange = (
    value: Tracker.CreationData["description"],
  ): void =>
    setTracker((draft) => {
      draft.description = value;
    });

  const onCategoryChange = (value: Tracker.CreationData["category"]): void =>
    setTracker((draft) => {
      draft.category = value;
    });

  const onSubmit = (): void => {
    const errors = validate();

    if (Object.keys(errors).length) return setErrors(errors);

    submitHandler(tracker);

    closeModal();
  };

  function validate() {
    const validationErrors: typeof errors = {};

    const { name, description = "", category } = tracker;

    if (!name.trim().length)
      validationErrors.name = t(
        "component.modal.edit_tracker_configuration.form.validation.name_required",
      );

    if (!description.trim().length)
      validationErrors.description = t(
        "component.modal.edit_tracker_configuration.form.validation.description_required",
      );

    if (!category.trim().length)
      validationErrors.category = t(
        "component.modal.edit_tracker_configuration.form.validation.category_required",
      );

    return validationErrors;
  }

  return (
    <ConfirmModal
      type="success"
      acceptButton={{
        onClick: onSubmit,
        disabled: isDisabled,
        text: t("component.modal.edit_tracker_configuration.button.submit"),
      }}
      cancelButton={{
        onClick: closeModal,
        text: t("component.modal.edit_tracker_configuration.button.cancel"),
      }}
      title={t("component.modal.edit_tracker_configuration.title")}
    >
      <Form
        onSubmit={onSubmit}
        disabled={isDisabled}
        className={styles.formWrapper}
      >
        <div className={styles.inputWrapper}>
          <Label
            leftText={t(
              "component.modal.edit_tracker_configuration.form.label.name",
            )}
          />
          <InputWithError
            ref={ref}
            error={errors.name}
            value={tracker.name}
            changeHandler={onNameChange}
            characterLimit={TRACKER_INPUT_LIMIT}
            placeholder={t(
              "component.modal.edit_tracker_configuration.form.placeholder.name",
            )}
          />
        </div>
        <div className={styles.inputWrapper}>
          <Label
            leftText={t(
              "component.modal.edit_tracker_configuration.form.label.description",
            )}
          />
          <InputWithError
            error={errors.description}
            changeHandler={onDescriptionChange}
            characterLimit={TRACKER_INPUT_LIMIT}
            value={tracker.description}
            placeholder={t(
              "component.modal.edit_tracker_configuration.form.placeholder.description",
            )}
          />
        </div>
        <div className={styles.inputWrapper}>
          <Label
            leftText={t(
              "component.modal.edit_tracker_configuration.form.label.category",
            )}
          />
          <InputWithError
            error={errors.category}
            changeHandler={onCategoryChange}
            characterLimit={TRACKER_INPUT_LIMIT}
            value={tracker.category}
            placeholder={t(
              "component.modal.edit_tracker_configuration.form.placeholder.category",
            )}
          />
        </div>
      </Form>
    </ConfirmModal>
  );
};
