import { FC, useEffect, useMemo } from "react";
import omit from "lodash/omit";
import { useImmer } from "use-immer";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./DuplicateSearchModal.module.scss";
import { withError } from "src/hocs";
import { generateDocId } from "src/store/utils";
import { Form, Input, Label } from "src/components";
import { useElementFocus, useModal, useTemporaryErrors } from "src/hooks";
import { SEARCH_INPUT_LIMIT, SEARCH_NAME_INPUT_LIMIT } from "src/constants";
import {
  selectLanguagesByKeywordsDataSource,
  selectLocationsByKeywordsDataSource,
} from "src/store/selectors";
import {
  removeExtraSpaces,
  getDifferenceInObjects,
  getLocationDefaultLanguageId,
} from "src/utils";
import {
  ConfirmModal,
  CountriesDropdown,
  LanguagesDropdown,
} from "src/features";

const InputWithError = withError(Input);

type Props = {
  search: Search.Data;
  duplicateHandler: (value: Search.CreationData, callback?: () => void) => void;
};

export const DuplicateSearchModal: FC<Props> = ({
  search: initialSearch,
  duplicateHandler,
}) => {
  const { t } = useTranslation();

  const [ref, setFocus] = useElementFocus();

  const { closeModal } = useModal();

  const { errors, setErrors } = useTemporaryErrors(3000);

  const defaultSearch = useMemo<Search.CreationData>(
    () =>
      omit({ ...initialSearch, id: generateDocId() }, "createdAt", "updatedAt"),
    [initialSearch],
  );

  const [search, setSearch] = useImmer<Search.CreationData>(defaultSearch);

  const locations = useSelector((state: Store.RootState) =>
    selectLocationsByKeywordsDataSource(state, search.keywordsDataSource),
  );

  const languages = useSelector((state: Store.RootState) =>
    selectLanguagesByKeywordsDataSource(state, search.keywordsDataSource),
  );

  const isSearchChanged = useMemo<boolean>(
    () => Boolean(getDifferenceInObjects(search, defaultSearch)),
    [search, defaultSearch],
  );

  const isDisabled = useMemo<boolean>(
    () => !isSearchChanged,
    [isSearchChanged],
  );

  useEffect(() => setFocus(), [setFocus]);

  const onSubjectChange = (value: string): void =>
    setSearch((draft) => {
      draft.subject = value;
    });

  const onDescriptionChange = (value: string): void =>
    setSearch((draft) => {
      draft.description = value;
    });

  const onLocationIdChange = (value: string): void =>
    setSearch((draft) => {
      if (value === search.locationId) return;

      const languageId = getLocationDefaultLanguageId(
        value,
        locations,
        languages,
      );

      draft.locationId = value;

      draft.languageId = languageId;
    });

  const onLanguageIdChange = (value: string): void =>
    setSearch((draft) => {
      draft.languageId = value;
    });

  const onSubmit = (): void => {
    const errors = validate();

    if (Object.keys(errors).length) return setErrors(errors);

    duplicateHandler(
      {
        ...search,
        subject: removeExtraSpaces(search.subject),
        description: removeExtraSpaces(search.description || ""),
      },
      closeModal,
    );
  };

  function validate() {
    const validationErrors: typeof errors = {};

    const { subject } = search;

    if (!subject.trim())
      validationErrors.name = t(
        "component.modal.duplicate_search.form.validation.name_required",
      );

    return validationErrors;
  }

  return (
    <ConfirmModal
      type="info"
      acceptButton={{
        text: t("component.modal.duplicate_search.button.submit"),
        onClick: onSubmit,
        disabled: isDisabled,
      }}
      cancelButton={{
        text: t("component.modal.duplicate_search.button.cancel"),
        onClick: closeModal,
      }}
      title={t("component.modal.duplicate_search.title", {
        name: defaultSearch.subject,
      })}
    >
      <Form
        onSubmit={onSubmit}
        disabled={isDisabled}
        className={styles.formWrapper}
      >
        <div className={styles.inputWrapper}>
          <Label
            leftText={t("component.modal.duplicate_search.form.label.name")}
          />
          <InputWithError
            ref={ref}
            error={errors.name}
            value={search.subject}
            changeHandler={onSubjectChange}
            characterLimit={SEARCH_NAME_INPUT_LIMIT}
            placeholder={t(
              "component.modal.duplicate_search.form.placeholder.name",
            )}
          />
        </div>
        <div className={styles.inputWrapper}>
          <Label
            leftText={t(
              "component.modal.duplicate_search.form.label.description",
            )}
          />
          <InputWithError
            error={errors.description}
            value={search.description}
            characterLimit={SEARCH_INPUT_LIMIT}
            changeHandler={onDescriptionChange}
            placeholder={t(
              "component.modal.duplicate_search.form.placeholder.description",
            )}
          />
        </div>
        <div className={styles.groupWrapper}>
          <div className={styles.inputWrapper}>
            <Label
              leftText={t(
                "component.modal.duplicate_search.form.label.location",
              )}
            />
            <CountriesDropdown
              hasDefaultStyles
              openingDirection="bottom-end"
              locationId={search.locationId}
              setLocationId={onLocationIdChange}
              keywordsDataSource={search.keywordsDataSource}
            />
          </div>
          <div className={styles.inputWrapper}>
            <Label
              leftText={t(
                "component.modal.duplicate_search.form.label.language",
              )}
            />
            <LanguagesDropdown
              hasDefaultStyles
              openingDirection="bottom-end"
              languageId={search.languageId}
              setLanguageId={onLanguageIdChange}
              keywordsDataSource={search.keywordsDataSource}
            />
          </div>
        </div>
      </Form>
    </ConfirmModal>
  );
};
