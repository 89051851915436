import { useContext } from "react";
import { createPortal } from "react-dom";

import styles from "./ModalPortal.module.scss";
import context from "src/context";

const PORTAL = document.getElementById("modal");

export const ModalPortal = () => {
  const { modalElement } = useContext(context);

  if (modalElement === null || !PORTAL) return null;

  return createPortal(
    <div className={styles.wrapper}>{modalElement}</div>,
    PORTAL,
  );
};
