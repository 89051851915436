import { FC, useMemo, useState } from "react";
import { useModal } from "src/hooks";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { ConfirmModal } from "src/features";
import { showToastNotification } from "src/utils";
import { selectUserId } from "src/store/selectors";
import { sendEnterpriseSubscriptionPlanRequest } from "src/store/subscriptionPlans/subscriptionPlansApi";

export const ConfirmSubscriptionPlanRequest: FC = () => {
  const { t } = useTranslation();

  const { closeModal } = useModal();

  const userId = useSelector(selectUserId);

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("idle");

  const isLoading = useMemo<boolean>(
    () => loadingStatus === "loading",
    [loadingStatus],
  );

  const onSubmit = () => {
    setLoadingStatus("loading");

    sendEnterpriseSubscriptionPlanRequest(userId)
      .then(() => {
        setLoadingStatus("succeeded");

        showToastNotification({
          type: "success",
          text: t(
            "component.modal.request_subscription_plan.status.success.request_sent",
          ),
        });
      })
      .catch((error) => {
        console.error(error);

        setLoadingStatus("failed");

        showToastNotification({
          type: "error",
          text: t("common.error.server_error"),
        });
      })
      .finally(closeModal);
  };

  return (
    <ConfirmModal
      type="info"
      acceptButton={{
        text: t("component.modal.request_subscription_plan.button.submit"),
        onClick: onSubmit,
      }}
      cancelButton={{
        text: t("component.modal.request_subscription_plan.button.cancel"),
        onClick: closeModal,
      }}
      title={t("component.modal.request_subscription_plan.title")}
      isLoading={isLoading}
    >
      <p>{t("component.modal.request_subscription_plan.body")}</p>
    </ConfirmModal>
  );
};
