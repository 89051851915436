import { FC, useMemo } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";

import styles from "./SelectedTrackersSection.module.scss";

// Inner imports
import { SelectedTracker } from "./components";

type Props = {
  selectedTrackers: Tracker.CreationData[];
  selectedSearches: Record<string, Search.CreationData[]>;
  selectTrackerHandler: (tracker: Tracker.CreationData) => void;
  unselectTrackerHandler: (tracker: Tracker.CreationData) => void;
  openTrackerSettingsHandler: (tracker: Tracker.CreationData) => void;
  reselectSearchesHandler: (
    tracker: Tracker.CreationData,
    searches: Search.CreationData[],
  ) => void;
  selectSearchHandler: (
    tracker: Tracker.CreationData,
    search: Search.CreationData,
  ) => void;
  updateTrackerHandler: ({
    id,
    changes,
    callback,
  }: {
    id: Tracker.CreationData["id"];
    changes: Tracker.CreationData;
    callback?: () => void;
  }) => void;
};

export const SelectedTrackersSection: FC<Props> = ({
  selectedTrackers,
  selectedSearches,
  selectSearchHandler,
  selectTrackerHandler,
  updateTrackerHandler,
  unselectTrackerHandler,
  reselectSearchesHandler,
  openTrackerSettingsHandler,
}) => {
  const { t } = useTranslation();

  const hasSelectedTrackers = useMemo<boolean>(
    () => Boolean(selectedTrackers.length),
    [selectedTrackers.length],
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <span>
          {t("page.create_tracker.selection.label.selected_trackers")}
        </span>
      </div>
      <div
        className={cx(
          styles.selectedTrackers,
          hasSelectedTrackers ? "" : styles.selectedTrackersEmpty,
        )}
      >
        {hasSelectedTrackers ? (
          selectedTrackers.map((tracker) => {
            const selectedTrackerSearches = selectedSearches[tracker.id] || [];

            return (
              <SelectedTracker
                key={tracker.id}
                tracker={tracker}
                selectedTrackers={selectedTrackers}
                selectSearchHandler={selectSearchHandler}
                selectedSearches={selectedTrackerSearches}
                updateTrackerHandler={updateTrackerHandler}
                duplicateTrackerHandler={selectTrackerHandler}
                unselectTrackerHandler={unselectTrackerHandler}
                reselectSearchesHandler={reselectSearchesHandler}
                openTrackerSettingsHandler={openTrackerSettingsHandler}
              />
            );
          })
        ) : (
          <span className={styles.placeholder}>
            {t("page.create_tracker.selection.label.select_suggested_tracker")}
          </span>
        )}
      </div>
    </div>
  );
};
