import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { useModal } from "src/hooks";
import { ROUTES } from "src/constants";
import { ConfirmModal } from "src/features";

type Props = {
  content: string;
};

export const UpgradeSubscriptionPlanModal: FC<Props> = ({ content }) => {
  const { t } = useTranslation();

  const history = useHistory();

  const { closeModal } = useModal();

  return (
    <ConfirmModal
      type="warning"
      title={t("component.modal.upgrade_subscription_plan.title")}
      cancelButton={{
        text: t("component.modal.upgrade_subscription_plan.button.cancel"),
        onClick: closeModal,
      }}
      acceptButton={{
        text: t("component.modal.upgrade_subscription_plan.button.submit"),
        onClick: () => {
          history.push(`${ROUTES.settingsPage}#billing`, {
            isSubscriptionPlansSidebarOpen: true,
          });

          closeModal();
        },
      }}
    >
      <span>{t(content)}</span>
    </ConfirmModal>
  );
};
