import { FC, memo, useState, useCallback } from "react";
import cx from "classnames";

import styles from "./Button.module.scss";
import { getElementContrastedColor } from "src/utils";

// Inner imports
import type { ButtonProps } from "./types";
import { useButtonTypeClassName } from "./hooks";

export const Button: FC<ButtonProps> = memo(
  ({
    className = "",
    buttonType = "primary",
    buttonSize = "medium",
    buttonStyle = "filled",
    content,
    disabled,
    ...props
  }) => {
    const [fontColor, setFontColor] = useState<string>("");

    const buttonTypeClassName = useButtonTypeClassName(buttonType, buttonStyle);

    const refCallback = useCallback(
      (element: HTMLButtonElement | null): void => {
        if (
          !element ||
          disabled ||
          buttonStyle === "transparent" ||
          buttonType !== "primary"
        )
          return;

        const newFontColor = getElementContrastedColor({
          element,
          minRatio: 3.8,
        });

        setFontColor(newFontColor);
      },
      [buttonStyle, buttonType, disabled],
    );

    return (
      <button
        {...props}
        ref={refCallback}
        className={cx(
          className,
          styles.button,
          styles[`button-${buttonSize}`],
          styles[`button-${buttonStyle}`],
          styles[buttonTypeClassName],
        )}
        style={{
          ...(disabled ? {} : { color: fontColor }),
          ...props.style,
        }}
        disabled={disabled}
      >
        {content || props.children}
      </button>
    );
  },
);
