import { FC, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./CreateNewTrackersCollectionModal.module.scss";
import { withError } from "src/hocs";
import { useAppDispatch } from "src/store";
import { Form, Input } from "src/components";
import { selectVisibleDashboards } from "src/store/selectors";
import { DASHBOARD_DEFAULT_VISIBILITY, ROUTES } from "src/constants";
import { useElementFocus, useModal, useTemporaryErrors } from "src/hooks";
import { createTrackersCollection, createDashboard } from "src/store/actions";
import {
  removeExtraSpaces,
  compareTwoStrings,
  showToastNotification,
} from "src/utils";
import { ConfirmModal } from "../ConfirmModal/ConfirmModal";

const InputWithError = withError(Input);

type Props = {
  trackerIds: Tracker.Data["id"][];
  submitHandler: () => void;
};

export const CreateNewTrackersCollectionModal: FC<Props> = ({
  trackerIds,
  submitHandler,
}) => {
  const { t } = useTranslation();

  const { errors, setErrors } = useTemporaryErrors(3000);

  const { closeModal } = useModal();

  const history = useHistory();

  const dispatch = useAppDispatch();

  const [ref, setFocus] = useElementFocus();

  const dashboards = useSelector(selectVisibleDashboards);

  const [trackersCollectionName, setTrackersCollectionName] =
    useState<TrackersCollection.Data["name"]>("");

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("idle");

  const isLoading = useMemo<boolean>(
    () => loadingStatus === "loading",
    [loadingStatus],
  );

  const isTrackersConfigurationNameChanged = useMemo<boolean>(
    () => Boolean(trackersCollectionName),
    [trackersCollectionName],
  );

  const isDisabled = useMemo<boolean>(
    () => isLoading || !isTrackersConfigurationNameChanged,
    [isLoading, isTrackersConfigurationNameChanged],
  );

  useEffect(() => setFocus(), [setFocus]);

  const onSubmit = async (): Promise<void> => {
    const errors = validate();

    if (Object.keys(errors).length) return setErrors(errors);

    try {
      setLoadingStatus("loading");

      const { id: trackersCollectionId } = await dispatch(
        createTrackersCollection({
          trackerIds,
          name: `${trackersCollectionName} Collection`,
        }),
      ).unwrap();

      const { id: dashboardId } = await dispatch(
        createDashboard({
          trackersCollectionId,
          name: trackersCollectionName,
          visibility: DASHBOARD_DEFAULT_VISIBILITY,
          originalDashboardId: null,
        }),
      ).unwrap();

      setLoadingStatus("succeeded");

      history.push(`${ROUTES.dashboardsHomePage}/${dashboardId}`);

      submitHandler();
    } catch (error) {
      console.error(error);

      setLoadingStatus("failed");

      showToastNotification({
        type: "error",
        text: t("common.error.server_error"),
      });
    }
  };

  const onReset = (): void => closeModal();

  function validate() {
    const errors: Errors = {};

    const trimmedName = removeExtraSpaces(trackersCollectionName).toLowerCase();

    if (!trimmedName)
      errors["name"] = t(
        "component.modal.create_trackers_collection.form.validation.name_required",
      );

    const isDashboardNameTaken = dashboards.some((dashboard) =>
      compareTwoStrings(dashboard.name, trackersCollectionName),
    );

    if (isDashboardNameTaken)
      errors["name"] = t(
        "component.modal.create_trackers_collection.form.validation.name_exists",
      );

    return errors;
  }

  return (
    <ConfirmModal
      type="success"
      acceptButton={{
        text: t("component.modal.create_trackers_collection.button.submit"),
        onClick: onSubmit,
        disabled: isDisabled,
      }}
      cancelButton={{
        text: t("component.modal.create_trackers_collection.button.cancel"),
        onClick: onReset,
      }}
      title={t("component.modal.create_trackers_collection.title")}
      isLoading={isLoading}
    >
      <Form
        onSubmit={onSubmit}
        disabled={isDisabled}
        className={styles.formWrapper}
      >
        <InputWithError
          value={trackersCollectionName}
          changeHandler={setTrackersCollectionName}
          error={errors["name"]}
          tabIndex={1}
          autoFocus
          ref={ref}
        />
      </Form>
    </ConfirmModal>
  );
};
