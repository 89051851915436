import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./SelectedSearchKeywordsInfo.module.scss";
import { Loader, Warning } from "src/assets/icons";
import { Button, Translation } from "src/components";
import { selectSearchConfigurationById } from "src/store/selectors";
import { formatToLocaleNumber, isSearchCreatedTypeGuard } from "src/utils";

type Props = {
  search: Search.Data | Search.CreationData;
  searchStatus: Search.Status | null;
  keywords: Search.Keyword[];
  keywordsStatus: LoadingStatus;
  openKeywordsHandler: (search: Search.Data) => void;
};

export const SelectedSearchKeywordsInfo: FC<Props> = ({
  search,
  searchStatus,
  keywords,
  keywordsStatus,
  openKeywordsHandler,
}) => {
  const { t } = useTranslation();

  const searchId = useMemo<Search.Data["id"]>(() => {
    if (!isSearchCreatedTypeGuard(search)) return "";

    return search.id;
  }, [search]);

  const searchConfiguration = useSelector((state: Store.RootState) =>
    selectSearchConfigurationById(state, searchId),
  );

  const isKeywordsLoading = useMemo<boolean>(
    () => keywordsStatus === "idle" || keywordsStatus === "loading",
    [keywordsStatus],
  );

  const isKeywordsWarningShown = useMemo<boolean>(
    () =>
      keywordsStatus === "succeeded" && searchStatus === "NO_SELECTED_KEYWORDS",
    [keywordsStatus, searchStatus],
  );

  const { selectedKeywordsVolume, selectedKeywordsCount } = useMemo(() => {
    let [selectedKeywordsVolume, selectedKeywordsCount] = [0, 0];

    if (!searchConfiguration?.keywords || !keywords)
      return { selectedKeywordsVolume, selectedKeywordsCount };

    for (const keyword of searchConfiguration.keywords) {
      const existingKeyword = keywords.find(({ string }) => string === keyword);

      if (!existingKeyword) continue;

      selectedKeywordsVolume += existingKeyword.totalVolume;
      selectedKeywordsCount++;
    }

    return {
      selectedKeywordsVolume: formatToLocaleNumber(selectedKeywordsVolume),
      selectedKeywordsCount,
    };
  }, [searchConfiguration?.keywords, keywords]);

  if (!isSearchCreatedTypeGuard(search) || isKeywordsLoading)
    return (
      <div className={styles.wrapper}>
        <Loader className={styles.loader} />
        <span>{t("component.selected_search.loader.process_keywords")}</span>
      </div>
    );

  if (isSearchCreatedTypeGuard(search) && isKeywordsWarningShown)
    return (
      <div className={styles.wrapper}>
        <div className={styles.warning}>
          <Warning />
          {keywords.length ? (
            <div>
              <span>
                {t("component.selected_search.label.empty_keywords_selection")}
              </span>
              <Button
                buttonSize="small"
                buttonStyle="transparent"
                className={styles.button}
                onClick={() => openKeywordsHandler(search)}
              >
                {t("component.selected_search.label.review_keywords")}
              </Button>
            </div>
          ) : (
            <span>
              {t("component.selected_search.label.incomplete_keywords")}
            </span>
          )}
        </div>
      </div>
    );

  return (
    <div className={styles.wrapper}>
      {keywords.length ? (
        <Translation
          i18nKey="component.selected_search.label.selected_keywords"
          values={{
            count: selectedKeywordsCount,
            volume: selectedKeywordsVolume,
          }}
          components={{
            button: (
              <Button
                className={styles.button}
                buttonStyle="transparent"
                onClick={() => openKeywordsHandler(search)}
              />
            ),
          }}
        />
      ) : (
        <span>{t("component.selected_search.label.no_keywords")}</span>
      )}
    </div>
  );
};
