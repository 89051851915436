import { FC, useMemo, memo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./LanguagesDropdown.module.scss";
import { Select, Tooltip } from "src/components";
import { SelectProps } from "src/components/selectors/Select/types";
import { selectLanguagesByKeywordsDataSource } from "src/store/selectors";

type Props = Pick<SelectProps, "openingDirection"> & {
  isDisabled?: boolean;
  hasDefaultStyles?: boolean;
  languageId: Language.Data["id"];
  keywordsDataSource: Search.KeywordsDataSource;
  setLanguageId: (value: Language.Data["id"]) => void;
};

export const LanguagesDropdown: FC<Props> = memo(
  ({
    languageId,
    setLanguageId,
    keywordsDataSource,
    hasDefaultStyles = false,
    isDisabled: defaultIsDisabled = false,
    openingDirection = "bottom-start",
  }) => {
    const { t } = useTranslation();

    const languages = useSelector((state: Store.RootState) =>
      selectLanguagesByKeywordsDataSource(state, keywordsDataSource),
    );

    const languagesOptions = useMemo<Option<Language.Data["id"]>[]>(
      () =>
        languages.map((language) => ({
          label: language.name,
          value: language.id,
        })),
      [languages],
    );

    const hasOneOption = useMemo<boolean>(
      () => languagesOptions.length === 1,
      [languagesOptions.length],
    );

    const isDisabled = useMemo<boolean>(
      () => defaultIsDisabled || hasOneOption,
      [defaultIsDisabled, hasOneOption],
    );

    const tooltip = useMemo<string>(() => {
      if (hasOneOption)
        return t("component.languages_dropdown.tooltip.single_option");

      return "";
    }, [hasOneOption, t]);

    const onLanguageSelect = (value: Language.Data["id"]): void =>
      setLanguageId(value);

    return (
      <div className={hasDefaultStyles ? "" : styles.wrapper}>
        <Tooltip content={tooltip}>
          <Select
            hasFilter
            value={languageId}
            isDisabled={isDisabled}
            options={languagesOptions}
            changeHandler={onLanguageSelect}
            openingDirection={openingDirection}
            inputClassName={hasDefaultStyles ? "" : styles.input}
            selectClassName={hasDefaultStyles ? "" : styles.select}
            dropdownClassName={hasDefaultStyles ? "" : styles.dropdown}
          />
        </Tooltip>
      </div>
    );
  },
);
