import { FC, useMemo } from "react";

import styles from "./SuggestedTracker.module.scss";
import { Button, Tooltip } from "src/components";
import { PlusCircleOutline } from "src/assets/icons";

type Props = {
  tracker: Tracker.CreationData;
  clickHandler: (value: Tracker.CreationData) => void;
};

export const SuggestedTracker: FC<Props> = ({ tracker, clickHandler }) => {
  const tooltip = useMemo<string>(() => tracker.description || "", [tracker]);

  return (
    <Tooltip content={tooltip}>
      <Button
        buttonSize="small"
        buttonStyle="outlined"
        className={styles.suggestedTracker}
        onClick={() => clickHandler(tracker)}
      >
        <span className={styles.suggestedTrackerName}>{tracker.name}</span>
        <PlusCircleOutline />
      </Button>
    </Tooltip>
  );
};
