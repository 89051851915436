import { ReactNode, ReactChild, useState } from "react";

import { useAuthObserver } from "src/app/hooks";

// Inner imports
import Context from "./index";

type IProps = {
  children: ReactChild;
};

const ContextProvider = ({ children }: IProps) => {
  const [modalElement, setModalElement] = useState<ReactNode>(null);

  const [isGlobalPreloaderShown, setIsGlobalPreloaderShown] =
    useState<boolean>(false);

  const [globalPreloaderText, setGlobalPreloaderText] = useState<string>("");

  const { isObserverSet: isAuthenticationObserverSet, isUserAuthenticated } =
    useAuthObserver();

  return (
    <Context.Provider
      value={{
        modalElement,
        setModalElement,
        globalPreloaderText,
        setGlobalPreloaderText,
        isGlobalPreloaderShown,
        setIsGlobalPreloaderShown,
        isUserAuthenticated,
        isAuthenticationObserverSet,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default ContextProvider;
