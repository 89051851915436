import * as yup from "yup";

import { SEARCH_KEYWORDS_DATA_SOURCES, SEARCH_STATUSES } from "src/constants";

export const searchSchema = yup
  .object({
    authorId: yup.string().required(),
    companyId: yup.string().required(),
    locationId: yup.string().required(),
    languageId: yup.string().required(),

    subject: yup.string().default(""),
    description: yup.string().default(""),
    status: yup
      .string<Search.Status>()
      .oneOf([...SEARCH_STATUSES])
      .required(),
    keywordsDataSource: yup
      .string<Search.KeywordsDataSource>()
      .oneOf([...SEARCH_KEYWORDS_DATA_SOURCES])
      .required(),

    createdAt: yup.string().default(""),
    updatedAt: yup.string().default(""),
  })
  .noUnknown()
  .required();

export const suggestedSearchesSchema = yup
  .array(
    yup.object({
      subject: yup.string().required(),
      description: yup.string().required(),
    }),
  )
  .default([]);

const searchKeywordValueSchema = yup
  .object({
    date: yup.string().required(),
    volume: yup.number().required(),
  })
  .noUnknown()
  .required();

export const searchKeywordSchema = yup
  .object({
    possibleDuplicateGroupId: yup.number().nullable().default(null),
    string: yup.string().required(),
    totalVolume: yup.number().required(),
    values: yup.array(searchKeywordValueSchema).required(),
  })
  .noUnknown()
  .required();

export const searchSelectedKeywordsSchema = yup
  .object({
    keywords: yup.array(yup.string().required()).required(),
  })
  .noUnknown()
  .required();

export const searchDescriptionSchema = yup.string().default("");

export const createSearchSchema = yup.string().required();

export type SuggestedSearchesSchemaType = yup.InferType<
  typeof suggestedSearchesSchema
>;
