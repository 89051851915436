import { FC, useMemo } from "react";

import styles from "./SuggestedSearch.module.scss";
import { Button, Tooltip } from "src/components";
import { PlusCircleOutline } from "src/assets/icons";

type Props = {
  search: Search.CreationData;
  searchCreateHandler: (newSearch: Search.CreationData) => void;
};

export const SuggestedSearch: FC<Props> = ({ search, searchCreateHandler }) => {
  const tooltip = useMemo<string>(() => search.description || "", [search]);

  return (
    <Tooltip content={tooltip}>
      <Button
        className={styles.suggestedSearch}
        onClick={() => searchCreateHandler(search)}
        buttonSize="small"
        buttonStyle="outlined"
      >
        <span className={styles.suggestedSearchName}>{search.subject}</span>
        <PlusCircleOutline />
      </Button>
    </Tooltip>
  );
};
