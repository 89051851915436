import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./SelectedTracker.module.scss";
import { useModal } from "src/hooks";
import { useAppDispatch } from "src/store";
import { createSearch } from "src/store/actions";
import { SEARCH_DEFAULT_KEYWORDS_DATA_SOURCE } from "src/constants";
import { selectLocationById, selectLanguageById } from "src/store/selectors";
import { getDefaultSuggestedSearch } from "src/pages/Trackers/CreateTracker/utils";
import {
  getKeywordSearchLink,
  showToastNotification,
  getElementContrastedColor,
} from "src/utils";
import {
  EditTrackerModal,
  DuplicateTrackerModal,
  TrackerKeywordsDataSourcesIcon,
} from "src/features";
import {
  Button,
  Tooltip,
  Preloader,
  Translation,
  ExternalLink,
} from "src/components";
import {
  Edit,
  GearOutline,
  ImageSearch,
  InfoOutline,
  DuplicateOutline,
  MinusCircleOutline,
  Search as SearchIcon,
} from "src/assets/icons";
import { useGetTrackerDescription } from "../../hooks";

type Props = {
  tracker: Tracker.CreationData;
  selectedTrackers: Tracker.CreationData[];
  selectedSearches: Search.CreationData[];
  unselectTrackerHandler: (tracker: Tracker.CreationData) => void;
  duplicateTrackerHandler: (tracker: Tracker.CreationData) => void;
  openTrackerSettingsHandler: (tracker: Tracker.CreationData) => void;
  updateTrackerHandler: ({
    id,
    changes,
    callback,
  }: {
    id: Tracker.CreationData["id"];
    callback?: () => void;
    changes: Tracker.CreationData;
  }) => void;
  reselectSearchesHandler: (
    tracker: Tracker.CreationData,
    searches: Search.CreationData[],
  ) => void;
  selectSearchHandler: (
    tracker: Tracker.CreationData,
    search: Search.CreationData,
  ) => void;
};

export const SelectedTracker: FC<Props> = ({
  tracker,
  selectedSearches,
  selectedTrackers,
  selectSearchHandler,
  updateTrackerHandler,
  unselectTrackerHandler,
  reselectSearchesHandler,
  duplicateTrackerHandler,
  openTrackerSettingsHandler,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { setModal } = useModal();

  const location = useSelector((state: Store.RootState) =>
    selectLocationById(state, tracker.locationId),
  );

  const language = useSelector((state: Store.RootState) =>
    selectLanguageById(state, tracker.languageId),
  );

  const [searchCreateLoadingStatus, setSearchCreateLoadingStatus] =
    useState<LoadingStatus>("idle");

  const {
    getTrackerDescription,
    loadingStatus: trackerDescriptionLoadingStatus,
  } = useGetTrackerDescription({ tracker, location, selectedTrackers });

  const locationName = useMemo<string>(
    () => location?.name || "",
    [location?.name],
  );

  const languageName = useMemo<string>(
    () => language?.name || "",
    [language?.name],
  );

  const { searchLink, imageSearchLink } = useMemo(
    () => ({
      searchLink: getKeywordSearchLink({ keyword: tracker.name, location }),
      imageSearchLink: getKeywordSearchLink({
        location,
        type: "image",
        keyword: tracker.name,
      }),
    }),
    [tracker.name, location],
  );

  const isLoading = useMemo<boolean>(
    () =>
      searchCreateLoadingStatus === "loading" ||
      trackerDescriptionLoadingStatus === "loading",
    [searchCreateLoadingStatus, trackerDescriptionLoadingStatus],
  );

  const hasDescription = useMemo<boolean>(
    () => Boolean(tracker.description),
    [tracker.description],
  );

  useEffect(() => {
    if (
      searchCreateLoadingStatus !== "idle" ||
      selectedSearches.length ||
      !tracker.description
    )
      return;

    const defaultSearch = getDefaultSuggestedSearch({
      subject: tracker.name,
      locationId: tracker.locationId,
      languageId: tracker.languageId,
      description: tracker.description,
      keywordsDataSource:
        tracker.keywordsDataSources[0] || SEARCH_DEFAULT_KEYWORDS_DATA_SOURCE,
    });

    setSearchCreateLoadingStatus("loading");

    dispatch(createSearch(defaultSearch))
      .unwrap()
      .then((newSearch) => {
        selectSearchHandler(tracker, newSearch);

        setSearchCreateLoadingStatus("succeeded");
      })
      .catch((error) => {
        console.error(error);

        showToastNotification({
          type: "error",
          text: t("common.error.server_error"),
        });

        setSearchCreateLoadingStatus("failed");
      });
  }, [
    t,
    tracker,
    dispatch,
    selectSearchHandler,
    selectedSearches.length,
    searchCreateLoadingStatus,
  ]);

  useEffect(() => {
    if (tracker.description || trackerDescriptionLoadingStatus !== "idle")
      return;

    getTrackerDescription().then((value) => {
      updateTrackerHandler({
        id: tracker.id,
        changes: { ...tracker, description: value },
      });

      if (value) return;

      showToastNotification({
        type: "warning",
        text: (
          <Translation
            values={{ name: tracker.name }}
            i18nKey="component.selected_tracker.status.warning.tracker_removed"
          />
        ),
      });

      unselectTrackerHandler(tracker);
    });
  }, [
    tracker,
    updateTrackerHandler,
    getTrackerDescription,
    unselectTrackerHandler,
    trackerDescriptionLoadingStatus,
  ]);

  const headerRefCallback = useCallback(
    (element: HTMLDivElement | null): void => {
      if (!element) return;

      element.style.color = getElementContrastedColor({ element });
    },
    [],
  );

  const onEditSubmit = ({
    id,
    changes,
    callback,
  }: {
    id: Tracker.CreationData["id"];
    callback: () => void;
    changes: Tracker.CreationData;
  }): void =>
    updateTrackerHandler({
      id,
      changes,
      callback: () => {
        reselectSearchesHandler(changes, []);

        setSearchCreateLoadingStatus("idle");

        callback();
      },
    });

  const onEditClick = (): void =>
    setModal(
      <EditTrackerModal tracker={tracker} submitHandler={onEditSubmit} />,
    );

  const onDuplicateClick = (): void =>
    setModal(
      <DuplicateTrackerModal
        tracker={tracker}
        submitHandler={duplicateTrackerHandler}
      />,
    );

  const openTrackerSettings = (): void => openTrackerSettingsHandler(tracker);

  return (
    <div className={styles.wrapper}>
      {isLoading && (
        <div className={styles.loaderWrapper}>
          <Preloader
            type="bar"
            text={t("component.selected_tracker.loader.create_search")}
          />
        </div>
      )}
      <div ref={headerRefCallback} className={styles.header}>
        <div className={styles.title}>
          <Tooltip content={tracker.name} className={styles.trackerName}>
            <span>{tracker.name}</span>
          </Tooltip>
          <Tooltip
            content={t("component.selected_tracker.tooltip.edit_tracker")}
          >
            <Button
              buttonSize="small"
              onClick={onEditClick}
              buttonStyle="transparent"
            >
              <Edit />
            </Button>
          </Tooltip>
        </div>
        <div className={styles.actions}>
          <Tooltip
            content={t("component.selected_tracker.tooltip.search_link")}
          >
            <Button buttonSize="small" buttonStyle="transparent">
              <ExternalLink href={searchLink}>
                <SearchIcon />
              </ExternalLink>
            </Button>
          </Tooltip>
          <Tooltip
            content={t("component.selected_tracker.tooltip.image_search_link")}
          >
            <Button buttonSize="small" buttonStyle="transparent">
              <ExternalLink href={imageSearchLink}>
                <ImageSearch />
              </ExternalLink>
            </Button>
          </Tooltip>
          <Tooltip content={t("component.selected_tracker.tooltip.settings")}>
            <Button
              buttonSize="small"
              buttonStyle="transparent"
              onClick={openTrackerSettings}
            >
              <GearOutline />
            </Button>
          </Tooltip>
        </div>
      </div>
      <hr className={styles.divider} />
      <div className={styles.content}>
        <div className={styles.description}>
          <span>{tracker.description}</span>
          {hasDescription && (
            <Tooltip
              content={t(
                "page.create_tracker.selection.tooltip.tracker_description",
              )}
            >
              <InfoOutline />
            </Tooltip>
          )}
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.settings}>
          <div className={styles.keywordsDataSource}>
            <TrackerKeywordsDataSourcesIcon
              keywordsDataSources={tracker.keywordsDataSources}
            />
          </div>
          <div className={styles.location} title={locationName}>
            <span>{locationName}</span>
          </div>
          <div className={styles.language} title={languageName}>
            <span>{tracker.languageId.toUpperCase()}</span>
          </div>
          <div className={styles.category} title={tracker.category}>
            <span>{tracker.category}</span>
          </div>
        </div>
        <div className={styles.actions}>
          <Tooltip
            content={t("component.selected_tracker.tooltip.duplicate_tracker")}
          >
            <Button
              buttonSize="small"
              buttonStyle="transparent"
              onClick={onDuplicateClick}
            >
              <DuplicateOutline />
            </Button>
          </Tooltip>
          <Tooltip
            content={t("component.selected_tracker.tooltip.unselect_tracker")}
          >
            <Button
              buttonSize="small"
              buttonStyle="transparent"
              onClick={() => unselectTrackerHandler(tracker)}
            >
              <MinusCircleOutline />
            </Button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
