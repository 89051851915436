import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { SEARCH_DEFAULT_KEYWORDS_DATA_SOURCE } from "src/constants";
import { selectSubscriptionPlanKeywordsDataSources } from "src/store/selectors";

export const useKeywordsDataSource = (
  defaultKeywordsDataSource: Search.KeywordsDataSource = SEARCH_DEFAULT_KEYWORDS_DATA_SOURCE,
): [
  Search.KeywordsDataSource,
  Dispatch<SetStateAction<Search.KeywordsDataSource>>,
] => {
  const keywordsDataSources = useSelector(
    selectSubscriptionPlanKeywordsDataSources,
  );

  const [keywordsDataSource, setKeywordsDataSource] =
    useState<Search.KeywordsDataSource>(SEARCH_DEFAULT_KEYWORDS_DATA_SOURCE);

  useEffect(() => {
    const isKeywordsDataSourceAvailable = keywordsDataSources.includes(
      defaultKeywordsDataSource,
    );

    if (defaultKeywordsDataSource && isKeywordsDataSourceAvailable)
      setKeywordsDataSource(defaultKeywordsDataSource);
  }, [defaultKeywordsDataSource, keywordsDataSources]);

  return [keywordsDataSource, setKeywordsDataSource];
};
