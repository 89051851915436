/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext, ReactNode } from "react";

export default createContext({
  modalElement: null as ReactNode,
  setModalElement: (value: ReactNode) => {},
  globalPreloaderText: "",
  setGlobalPreloaderText: (value: string) => {},
  isGlobalPreloaderShown: false,
  setIsGlobalPreloaderShown: (value: boolean) => {},
  isUserAuthenticated: false,
  isAuthenticationObserverSet: false,
});
