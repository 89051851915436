import { memo, useCallback, useMemo, useState } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import BarLoader from "react-spinners/BarLoader";
import { useSelector } from "react-redux";
import cx from "classnames";

import styles from "./Preloader.module.scss";
import { getElementContrastedColor } from "src/utils";
import { selectWhiteLabel } from "src/store/selectors";

// Inner imports
import { PRELOADER_COLOR_DARK, PRELOADER_COLOR_LIGHT } from "./constants";

type Props = {
  className?: string;
  type?: "scale" | "bar";
  modifier?: "global" | "inner";
  text?: string;
};

export const Preloader = memo(
  ({ className = "", type = "scale", modifier, text = "" }: Props) => {
    const { styles: whiteLabelStyles } = useSelector(selectWhiteLabel);

    const [preloaderColor, setPreloaderColor] = useState<string>(
      whiteLabelStyles.primaryColor,
    );

    const PreloaderComponent = useMemo<JSX.Element>(() => {
      switch (type) {
        case "bar":
          return (
            <BarLoader
              color={preloaderColor}
              width="80px"
              height="4px"
              loading
            />
          );
        case "scale":
        default:
          return (
            <ScaleLoader
              color={preloaderColor}
              width="8px"
              height="40px"
              loading
            />
          );
      }
    }, [preloaderColor, type]);

    const refCallback = useCallback((element: HTMLElement | null): void => {
      const parentElement = element?.parentElement;

      if (!element || !parentElement) return;

      const color = getElementContrastedColor({
        element,
        colors: [PRELOADER_COLOR_LIGHT, PRELOADER_COLOR_DARK],
        parentElement,
      });

      setPreloaderColor(color);
    }, []);

    return (
      <div
        className={cx(
          styles.preloader,
          modifier ? styles["preloader_" + modifier] : "",
          className,
        )}
        style={{ color: preloaderColor }}
        ref={refCallback}
      >
        {PreloaderComponent}
        {text && <span style={{ color: preloaderColor }}>{text}</span>}
      </div>
    );
  },
);
