import {
  combineReducers,
  configureStore,
  AnyAction,
  Reducer,
} from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import { checkIsLocalEnvironment } from "src/utils";
import { DEFAULT_LANGUAGE_ID, DEFAULT_LOCATION_ID } from "src/constants";

import application from "./application/applicationSlice";
import user from "./user/userSlice";
import company from "./company/companySlice";
import whiteLabel from "./whiteLabel/whiteLabelSlice";
import subscriptionPlans from "./subscriptionPlans/subscriptionPlansSlice";
import searches from "./searches/searchesSlice";
import searchesConfiguration from "./searchesConfiguration/searchesConfigurationSlice";
import trackers from "./trackers/trackersSlice";
import trackersCollections from "./trackersCollections/trackersCollectionsSlice";
import locations from "./locations/locationsSlice";
import languages from "./languages/languagesSlice";
import widgetsInfo from "./widgetsInfo/widgetsInfoSlice";
import dashboards from "./dashboards/dashboardsSlice";
import widgets from "./widgets/widgetsSlice";
import events from "./events/eventsSlice";
import dashboardDateRanges from "./dashboardDateRanges/dashboardDateRangesSlice";
import widgetSyncs from "./widgetSyncs/widgetSyncsSlice";

const appReducer = combineReducers({
  application,
  user,
  company,
  whiteLabel,
  subscriptionPlans,
  searches,
  searchesConfiguration,
  trackers,
  trackersCollections,
  locations,
  languages,
  widgetsInfo,
  dashboards,
  dashboardDateRanges,
  widgets,
  events,
  widgetSyncs,
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === "user/log-out") {
    const applicationState = {
      ...state.application,
      latestTrackerConfig: {
        locationId: DEFAULT_LOCATION_ID,
        languageId: DEFAULT_LANGUAGE_ID,
      },
    };

    state = { application: applicationState } as RootState;
  }

  return appReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  devTools: checkIsLocalEnvironment(),
});

export type RootState = ReturnType<typeof appReducer>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;

export default store;
