import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { languagesAdapter } from "./languagesSlice";

export const {
  selectAll: selectLanguages,
  selectEntities: selectLanguagesEntities,
  selectById: selectLanguageById,
} = languagesAdapter.getSelectors<Store.RootState>(
  ({ languages }) => languages,
);

export const selectLanguagesStatus = createDraftSafeSelector(
  ({ languages }: Store.RootState) => languages,
  (languages) => languages.status,
);

export const selectLanguagesByKeywordsDataSource = createDraftSafeSelector(
  (
    state: Store.RootState,
    keywordsDataSource: Search.KeywordsDataSource,
  ): [ReturnType<typeof selectLanguages>, Search.KeywordsDataSource] => [
    selectLanguages(state),
    keywordsDataSource,
  ],
  ([languages, keywordsDataSource]) =>
    languages.filter(({ keywordsDataSources }) =>
      keywordsDataSources.includes(keywordsDataSource),
    ),
);
