import { FC, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./EditInsightsWidgetDataModal.module.scss";
import { useModal } from "src/hooks";
import { ConfirmModal } from "src/features";
import { useAppDispatch } from "src/store";
import { showToastNotification } from "src/utils";
import { updateInsightsWidgetData } from "src/store/actions";
import { Checkbox, Form, RichTextEditor } from "src/components";
import { selectWidgetData, selectUserId } from "src/store/selectors";

// Inner imports
import { EDIT_INSIGHTS_INPUT_LIMIT, WIDGET_ID } from "./constants";

type Props = {
  trackersCollectionId: TrackersCollection.Data["id"];
  dashboardDateRangeId: DashboardDateRange.Data["id"];
};

export const EditInsightsWidgetDataModal: FC<Props> = ({
  dashboardDateRangeId,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { closeModal } = useModal();

  const widgetData = useSelector((state: Store.RootState) =>
    selectWidgetData(state, dashboardDateRangeId, WIDGET_ID),
  );

  const [isChecked, setIsChecked] = useState<boolean>(false);

  const userId = useSelector(selectUserId);

  const defaultValue = useMemo<string>(() => {
    if (!widgetData) return "";

    const { customPrompt, aiPrompt } = widgetData.data;

    if (!customPrompt && !aiPrompt) return "";

    return customPrompt.value || aiPrompt.value;
  }, [widgetData]);

  const [value, setValue] = useState<string | undefined>(defaultValue);

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("idle");

  const isLoading = useMemo<boolean>(
    () => loadingStatus === "loading",
    [loadingStatus],
  );

  const isValueChanged = useMemo<boolean>(
    () => defaultValue !== value,
    [defaultValue, value],
  );

  const isCheckboxDisabled = useMemo(
    () => isLoading || !isValueChanged || !value,
    [isLoading, isValueChanged, value],
  );

  const isDisabled = useMemo<boolean>(
    () => isLoading || !isValueChanged || !value || !isChecked,
    [isLoading, isValueChanged, value, isChecked],
  );
  const onValueChange = (value: string = ""): void => setValue(value);

  const onCheckboxClick = (): void => setIsChecked((value) => !value);

  const onSubmit = async (): Promise<void> => {
    if (!value || isDisabled || !widgetData) return;

    try {
      setLoadingStatus("loading");

      const updatedAt = new Date().toISOString();

      await dispatch(
        updateInsightsWidgetData({
          dashboardDateRangeId,
          changes: {
            ...widgetData,
            data: {
              ...widgetData.data,
              customPrompt: { value, authorId: userId, updatedAt },
            },
          },
        }),
      ).unwrap();

      showToastNotification({
        id: "insights-update-success",
        type: "success",
        text: t("component.modal.edit_insights.status.success.data_updated"),
      });

      setLoadingStatus("succeeded");

      closeModal();
    } catch (error) {
      console.error(error);

      showToastNotification({
        id: "insights-update-fail",
        type: "error",
        text: t("server_error"),
      });

      setLoadingStatus("failed");
    }
  };

  return (
    <ConfirmModal
      type="success"
      title={t("component.modal.edit_insights.title")}
      acceptButton={{
        text: t("component.modal.edit_insights.button.submit"),
        onClick: onSubmit,
        buttonSize: "small",
        disabled: isDisabled,
      }}
      cancelButton={{
        text: t("component.modal.edit_insights.button.cancel"),
        buttonSize: "small",
        onClick: closeModal,
      }}
      isLoading={isLoading}
    >
      <div className={styles.formWrapper}>
        <Form onSubmit={onSubmit}>
          <RichTextEditor
            value={value}
            preview="edit"
            changeValueHandler={onValueChange}
            characterLimit={EDIT_INSIGHTS_INPUT_LIMIT}
          />
        </Form>
        <div className={styles.checkboxWrapper}>
          <Checkbox
            isChecked={isChecked}
            onClick={onCheckboxClick}
            className={styles.checkbox}
            isDisabled={isCheckboxDisabled}
          />
          <span>
            {t("component.modal.edit_insights.checkbox.confirmation")}
          </span>
        </div>
      </div>
    </ConfirmModal>
  );
};
