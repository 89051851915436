import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import styles from "./SuggestedCategories.module.scss";
import { Preloader } from "src/components";
import { formatTrackerCategoryKey } from "src/utils";

// Inner imports
import { SuggestedCategory } from "./components";

type Props = {
  suggestedCategories: Tracker.CategoryEntity[];
  suggestedCategoriesStatus: LoadingStatus;
  selectSuggestedCategoryHandler: (category: Tracker.CategoryEntity) => void;
};

export const SuggestedCategories: FC<Props> = ({
  suggestedCategories,
  suggestedCategoriesStatus,
  selectSuggestedCategoryHandler,
}) => {
  const { t } = useTranslation();

  const isLoading = useMemo<boolean>(
    () => suggestedCategoriesStatus === "loading",
    [suggestedCategoriesStatus],
  );

  if (isLoading)
    return (
      <div className={styles.wrapper}>
        <Preloader
          text={t(
            "page.create_tracker.identification.loader.download_suggested_categories",
          )}
        />
      </div>
    );

  if (!isLoading && !suggestedCategories.length)
    return (
      <div className={styles.wrapper}>
        <div className={styles.placeholder}>
          <span className={styles.placeholderTitle}>
            {t(
              "page.create_tracker.identification.label.no_suggested_categories",
            )}
          </span>
          <span>
            {t(
              "page.create_tracker.identification.label.empty_result_update_query",
            )}
          </span>
        </div>
      </div>
    );

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <span className={styles.query}>
          {t("page.create_tracker.identification.label.select_category")}
        </span>
      </div>
      <div className={styles.categories}>
        {suggestedCategories.map((category) => (
          <SuggestedCategory
            category={category}
            key={formatTrackerCategoryKey(category)}
            selectSuggestedCategoryHandler={selectSuggestedCategoryHandler}
          />
        ))}
      </div>
      <div className={styles.footer}>
        <span>
          {t("page.create_tracker.identification.label.update_query")}
        </span>
      </div>
    </div>
  );
};
