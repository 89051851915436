import { FC, useCallback, useMemo, useState } from "react";
import cx from "classnames";
import { useImmer } from "use-immer";
import { useTranslation } from "react-i18next";

import styles from "./SelectedSearchesSection.module.scss";
import { KeywordsSidebar } from "src/features";
import { isSearchCreatedTypeGuard } from "src/utils";

// Inner imports
import { SelectedSearch } from "./components";

type Props = {
  saveSearchHandler: (search: Search.Data) => void;
  selectedSearches: Array<Search.Data | Search.CreationData>;
  selectSearchHandler: (search: Search.CreationData) => void;
  unselectSearchHandler: (search: Search.CreationData) => void;
  updateSearchHandler: ({
    id,
    changes,
  }: {
    id: Search.Data["id"];
    changes: Search.CreationData;
  }) => void;
};

export const SelectedSearchesSection: FC<Props> = ({
  selectedSearches,
  saveSearchHandler,
  selectSearchHandler,
  updateSearchHandler,
  unselectSearchHandler,
}) => {
  const { t } = useTranslation();

  const [isSidebarOpened, setIsSidebarOpened] = useState<boolean>(false);

  const [keywordsSearchId, setKeywordsSearchId] =
    useState<Search.Data["id"]>("");

  const defaultKeywords = useMemo<
    Record<Search.Data["id"], Search.KeywordsData>
  >(() => {
    const keywords = new Map<Search.Data["id"], Search.KeywordsData>();

    for (const search of selectedSearches)
      keywords.set(search.id, { keywords: [], status: "idle" });

    return Object.fromEntries(keywords);
  }, [selectedSearches]);

  const [keywords, setKeywords] =
    useImmer<Record<Search.Data["id"], Search.KeywordsData>>(defaultKeywords);

  const hasSelectedSearches = useMemo<boolean>(
    () => Boolean(selectedSearches.length),
    [selectedSearches.length],
  );

  const getKeywordsDataBySearchId = useCallback(
    (searchId: Search.Data["id"]): Search.KeywordsData | undefined =>
      keywords[searchId],
    [keywords],
  );

  const getKeywordsDataBySearch = useCallback(
    (
      search: Search.Data | Search.CreationData,
    ): Search.KeywordsData | undefined => {
      if (!isSearchCreatedTypeGuard(search)) return;

      return keywords[search.id];
    },
    [keywords],
  );

  const openKeywords = ({ id: searchId }: Search.Data): void => {
    setIsSidebarOpened(true);
    setKeywordsSearchId(searchId);
  };

  const updateSearchKeywords = (
    searchId: Search.Data["id"],
    keywords: Search.Keyword[],
    status: LoadingStatus,
  ): void =>
    setKeywords((draft) => {
      draft[searchId] = { keywords, status };
    });

  return (
    <div className={styles.wrapper}>
      {isSidebarOpened && (
        <KeywordsSidebar
          isOpened={isSidebarOpened}
          searchId={keywordsSearchId}
          setIsOpened={setIsSidebarOpened}
          keywordsData={getKeywordsDataBySearchId(keywordsSearchId)}
        />
      )}
      <div className={styles.title}>
        <span>
          {t("page.create_tracker.configuration.label.selected_searches")}
        </span>
      </div>
      <div
        className={cx(
          styles.selectedSearches,
          hasSelectedSearches ? "" : styles.selectedSearchesEmpty,
        )}
      >
        {hasSelectedSearches ? (
          selectedSearches.map((search) => (
            <SelectedSearch
              search={search}
              key={search.id}
              openKeywordsHandler={openKeywords}
              saveSearchHandler={saveSearchHandler}
              updateSearchHandler={updateSearchHandler}
              duplicateSearchHandler={selectSearchHandler}
              unselectSearchHandler={unselectSearchHandler}
              keywordsData={getKeywordsDataBySearch(search)}
              updateSearchKeywordsHandler={updateSearchKeywords}
            />
          ))
        ) : (
          <span className={styles.placeholder}>
            {t(
              "page.create_tracker.configuration.label.select_suggested_search",
            )}
          </span>
        )}
      </div>
    </div>
  );
};
