import { useMemo, memo, InputHTMLAttributes, forwardRef } from "react";
import cx from "classnames";

import styles from "./InputWithIcon.module.scss";
import { getIconByName } from "src/utils";
import { Cross } from "src/assets/icons";
import { Input } from "../Input/Input";

type Props = {
  className?: string;
  inputClassName?: string;
  title?: string;
  changeHandler?: (value: string) => void;
  icon: AppIcon;
  hasClearButton?: boolean;
} & Omit<InputHTMLAttributes<HTMLInputElement>, "className">;

export const InputWithIcon = memo(
  forwardRef<HTMLInputElement, Props>(
    (
      {
        icon,
        hasClearButton,
        changeHandler,
        value,
        className,
        inputClassName,
        ...props
      },
      ref,
    ) => {
      const Icon = useMemo<JSX.Element | null>(
        () => getIconByName(icon, styles.icon),
        [icon],
      );

      const isClearButtonVisible = useMemo<boolean>(
        () => Boolean(hasClearButton && value),
        [hasClearButton, value],
      );

      const onClearClick = () => changeHandler?.("");

      return (
        <div className={cx(styles.wrapper, className)}>
          {Icon}
          <Input
            {...props}
            ref={ref}
            value={value}
            changeHandler={changeHandler}
            inputClassName={cx(
              styles.inputWithIcon,
              inputClassName,
              isClearButtonVisible ? styles.inputWithClearButton : "",
            )}
          />
          {isClearButtonVisible && (
            <button onClick={onClearClick} className={styles.clearButton}>
              <Cross />
            </button>
          )}
        </div>
      );
    },
  ),
);
