import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { locationsAdapter } from "./locationsSlice";

export const {
  selectAll: selectLocations,
  selectEntities: selectLocationsEntities,
  selectById: selectLocationById,
} = locationsAdapter.getSelectors<Store.RootState>(
  ({ locations }) => locations,
);

export const selectLocationsStatus = createDraftSafeSelector(
  ({ locations }: Store.RootState) => locations,
  (locations) => locations.status,
);

export const selectActiveLocations = createDraftSafeSelector(
  selectLocations,
  (locations) => locations.filter(({ isActive }) => isActive),
);

export const selectLocationsByKeywordsDataSource = createDraftSafeSelector(
  (
    state: Store.RootState,
    keywordsDataSource: Search.KeywordsDataSource,
  ): [ReturnType<typeof selectActiveLocations>, Search.KeywordsDataSource] => [
    selectActiveLocations(state),
    keywordsDataSource,
  ],
  ([locations, keywordsDataSource]) =>
    locations.filter(({ keywordsDataSources }) =>
      keywordsDataSources.includes(keywordsDataSource),
    ),
);

export const selectLocationLanguageId = createDraftSafeSelector(
  (
    state: Store.RootState,
    locationId: Location.Data["id"],
  ): [ReturnType<typeof selectLocationById>] => [
    selectLocationById(state, locationId),
  ],
  ([location]) => location?.languageIds[0] || "",
);
