import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import {
  selectCompany,
  selectLocationsByKeywordsDataSource,
} from "src/store/selectors";
import {
  DEFAULT_LOCATION_ID,
  PRIORITIZED_LOCATION_ID,
  SEARCH_DEFAULT_KEYWORDS_DATA_SOURCE,
} from "src/constants";

export const useLocationId = ({
  locationId: initialLocationId = "",
  keywordsDataSource = SEARCH_DEFAULT_KEYWORDS_DATA_SOURCE,
}: {
  locationId?: Location.Data["id"];
  keywordsDataSource?: Search.KeywordsDataSource;
}): [Location.Data["id"], Dispatch<SetStateAction<Location.Data["id"]>>] => {
  const locations = useSelector((state: Store.RootState) =>
    selectLocationsByKeywordsDataSource(state, keywordsDataSource),
  );

  const company = useSelector(selectCompany);

  const companyLocationId = useMemo<Location.Data["id"]>(() => {
    const { countryCode: companyCountryCode } = company;

    return (
      locations.find(({ countryCode }) => countryCode === companyCountryCode)
        ?.id || ""
    );
  }, [company, locations]);

  const firstLocationId = useMemo<Location.Data["id"]>(
    () => locations[0]?.id || "",
    [locations],
  );

  const defaultLocationId = useMemo<Location.Data["id"]>(
    () => locations.find(({ id }) => id === DEFAULT_LOCATION_ID)?.id || "",
    [locations],
  );

  const [locationId, setLocationId] = useState<Location.Data["id"]>(
    initialLocationId ||
      companyLocationId ||
      defaultLocationId ||
      firstLocationId,
  );

  useEffect(() => {
    if (initialLocationId) setLocationId(initialLocationId);
  }, [initialLocationId]);

  useEffect(() => {
    const isLocationAvailable = locations.some(({ id }) => id === locationId);

    if (!isLocationAvailable) setLocationId(PRIORITIZED_LOCATION_ID);
  }, [locationId, locations]);

  return [locationId, setLocationId];
};
