import { TFunction } from "i18next";
import isEqual from "lodash/isEqual";
import { Dictionary } from "@reduxjs/toolkit";
import { RouteComponentProps } from "react-router-dom";

import { AppDispatch } from "src/store";
import { generateDocId } from "src/store/utils";
import { createSearch } from "src/store/actions";
import { isSearchCreatedTypeGuard } from "src/utils";
import {
  ROUTES,
  DEFAULT_LANGUAGE_ID,
  SEARCH_DEFAULT_STATUS,
  PRIORITIZED_LOCATION_ID,
  SEARCH_DEFAULT_KEYWORDS_DATA_SOURCE,
} from "src/constants";

// Inner imports
import { Step } from "./types";

export const formatNewTrackerName = (
  name: Tracker.Data["name"],
  location: string,
): Tracker.Data["name"] => `${name} - ${location}`;

export const redirectToStep = ({
  step,
  history,
  location,
  queryParams: rawQueryParams,
}: {
  step: Step;
  history: RouteComponentProps["history"];
  location: RouteComponentProps["location"];
  queryParams?: Record<string, string>;
}): void => {
  const [queryParams, newQueryParams, locationQueryParams] = [
    new URLSearchParams(),
    new URLSearchParams(rawQueryParams),
    new URLSearchParams(location.search),
  ];

  for (const [key, value] of locationQueryParams) queryParams.set(key, value);

  for (const [key, value] of newQueryParams) queryParams.set(key, value);

  switch (step) {
    case "selection": {
      history.push(`${ROUTES.trackerSelection}?${queryParams.toString()}`);

      break;
    }
    case "configuration": {
      history.push(`${ROUTES.trackerConfiguration}?${queryParams.toString()}`);

      break;
    }
    case "identification":
    default: {
      history.push(`${ROUTES.trackerIdentification}?${queryParams.toString()}`);

      break;
    }
  }
};

export const getDefaultSuggestedSearch = ({
  subject,
  description,
  locationId,
  languageId,
  keywordsDataSource,
}: {
  subject: string;
  description: string;
  locationId: Location.Data["id"];
  languageId: Language.Data["id"];
  keywordsDataSource: Search.KeywordsDataSource;
}): Search.CreationData => ({
  subject,
  locationId,
  languageId,
  description,
  keywordsDataSource,
  id: generateDocId(),
  status: SEARCH_DEFAULT_STATUS,
});

export const formatTrackerWithSearches = (
  tracker: Tracker.CreationData,
  searches: Array<Search.Data | Search.CreationData>,
): Tracker.CreationData => {
  let [trackerLanguageId, trackerLocationId] = [
    tracker.languageId,
    tracker.locationId,
  ];

  const [languageIds, locationIds] = [
    new Set<Language.Data["id"]>(),
    new Set<Location.Data["id"]>(),
  ];

  for (const { locationId, languageId } of searches) {
    languageIds.add(languageId);

    locationIds.add(locationId);
  }

  if (languageIds.size === 1)
    trackerLanguageId = [...languageIds][0] || trackerLanguageId;
  if (languageIds.size > 1) trackerLanguageId = DEFAULT_LANGUAGE_ID;

  if (locationIds.size === 1)
    trackerLocationId = [...locationIds][0] || trackerLocationId;
  if (locationIds.size > 1) trackerLocationId = PRIORITIZED_LOCATION_ID;

  return {
    ...tracker,
    languageId: trackerLanguageId,
    locationId: trackerLocationId,
  };
};

export const formatSelectedTrackers = async ({
  t,
  trackers,
  dispatch,
  locations,
  selectedTrackers,
  selectedSearches,
}: {
  t: TFunction;
  dispatch: AppDispatch;
  trackers: Tracker.Data[];
  selectedTrackers: Tracker.CreationData[];
  locations: Dictionary<Location.Data>;
  selectedSearches: Record<string, Search.CreationData[]>;
}): Promise<Array<Tracker.CreationData & Pick<Tracker.Data, "searchIds">>> => {
  const formattedTrackers = new Set<
    Tracker.CreationData & Pick<Tracker.Data, "searchIds">
  >();

  for (const tracker of selectedTrackers) {
    const trackerSearches = selectedSearches[tracker.id] || [];

    const searchIds = new Set<Search.Data["id"]>();

    for (const search of trackerSearches) {
      if (!isSearchCreatedTypeGuard(search)) continue;

      searchIds.add(search.id);
    }

    if (!searchIds.size) {
      const defaultSuggestedSearch = getDefaultSuggestedSearch({
        subject: tracker.name,
        locationId: tracker.locationId,
        languageId: tracker.languageId,
        description: tracker.description,
        keywordsDataSource:
          tracker.keywordsDataSources[0] || SEARCH_DEFAULT_KEYWORDS_DATA_SOURCE,
      });

      const { id: searchId } = await dispatch(
        createSearch(defaultSuggestedSearch),
      ).unwrap();

      searchIds.add(searchId);
    }

    const trackerName = getTrackerNameWithLocation({
      t,
      tracker,
      trackers,
      locations,
      trackerSearches,
    });

    formattedTrackers.add({
      ...tracker,
      name: trackerName,
      searchIds: [...searchIds],
    });
  }

  return [...formattedTrackers];
};

function getTrackerNameWithLocation({
  t,
  tracker,
  trackers,
  locations,
  trackerSearches,
}: {
  t: TFunction;
  trackers: Tracker.Data[];
  tracker: Tracker.CreationData;
  locations: Dictionary<Location.Data>;
  trackerSearches: Search.CreationData[];
}): string {
  const locationName = getTrackerLocationName(t, trackerSearches, locations);

  const initialTrackerName = formatNewTrackerName(tracker.name, locationName);

  let [trackerNameIndex, trackerName] = [0, initialTrackerName];

  for (const { name, keywordsDataSources } of trackers) {
    const [sortedKeywordsDataSources, sortedTrackerKeywordsDataSources] = [
      [...keywordsDataSources].sort(),
      [...tracker.keywordsDataSources].sort(),
    ];

    const [isSameName, isSameKeywordsDataSources] = [
      name === trackerName,
      isEqual(sortedKeywordsDataSources, sortedTrackerKeywordsDataSources),
    ];

    if (!isSameName || !isSameKeywordsDataSources) continue;

    trackerNameIndex++;

    trackerName = `${initialTrackerName} (${trackerNameIndex})`;
  }

  return trackerName;
}

function getTrackerLocationName(
  t: TFunction,
  searches: Search.CreationData[],
  locations: Dictionary<Location.Data>,
): string {
  const locationIds = new Set<Location.Data["id"]>();

  for (const { locationId } of searches) {
    const location = locations[locationId];

    if (location) locationIds.add(locationId);
  }

  if (locationIds.size > 1) return t("tracker.label.location_mixed");

  const locationId = [...locationIds][0];

  if (!locationId) return "";

  const location = locations[locationId];

  if (!location) return "";

  return location.city || location.region || location.country || location.name;
}
