import { FC, MouseEvent, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./DashboardDateRangeActions.module.scss";
import { useModal } from "src/hooks";
import { TrashOutline } from "src/assets/icons";
import { Button, Tooltip } from "src/components";
import { selectEventsByDashboardId } from "src/store/selectors";
import { ConfirmDeleteDashboardDateRangeModal } from "src/features";

type Props = {
  dashboardDateRange: DashboardDateRange.Data;
};

export const DashboardDateRangeActions: FC<Props> = ({
  dashboardDateRange,
}) => {
  const { t } = useTranslation();

  const { setModal } = useModal();

  const events = useSelector((state: Store.RootState) =>
    selectEventsByDashboardId(state, dashboardDateRange.trackersCollectionId),
  );

  const tooltip = useMemo<AppIcon>(() => {
    const isEventDashboardDateRange = events.find(
      (event) => event.dashboardDateRangeId === dashboardDateRange.id,
    );

    if (isEventDashboardDateRange)
      return t("page.dashboard.tooltip.delete_event_dashboard_date_range");

    return t("page.dashboard.tooltip.delete_dashboard_date_range");
  }, [dashboardDateRange.id, events, t]);

  const onDelete = (event: MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();

    setModal(
      <ConfirmDeleteDashboardDateRangeModal
        dashboardDateRangeId={dashboardDateRange.id}
      />,
    );
  };

  return (
    <Tooltip content={tooltip}>
      <Button
        buttonSize="small"
        onClick={onDelete}
        buttonStyle="transparent"
        className={styles.button}
      >
        <TrashOutline />
      </Button>
    </Tooltip>
  );
};
