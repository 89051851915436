import { FC, useCallback, useContext, useEffect, useMemo } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import styles from "./Auth.module.scss";
import Context from "src/context";
import { ROUTES } from "src/constants";
import { Preloader } from "src/components";
import { useQueryParams } from "src/hooks";
import { AuthHeader, Footer } from "src/features";
import { selectCompanyById, selectWhiteLabel } from "src/store/selectors";
import {
  useExternalCompanyDataLoader,
  useWhiteLabelDataLoader,
} from "../Home/hooks";

// Pages
import Login from "src/pages/Auth/Login/Login";
import SignUp from "src/pages/Auth/SignUp/SignUp";
import PasswordReset from "src/pages/Auth/PasswordReset/PasswordReset";
import EmailVerification from "src/containers/EmailVerification/EmailVerification";

const Auth: FC = () => {
  const history = useHistory();

  const { company: companyId = "" } = useQueryParams();

  const { isUserAuthenticated, isAuthenticationObserverSet } =
    useContext(Context);

  const { loadingStatus: whiteLabelLoadingStatus } = useWhiteLabelDataLoader();

  const { loadingStatus: externalCompanyDataLoader } =
    useExternalCompanyDataLoader(companyId);

  const {
    features: { signupEnabled },
  } = useSelector(selectWhiteLabel);

  const company = useSelector((state: Store.RootState) =>
    selectCompanyById(state, companyId),
  );

  const isMainDataLoaded = useMemo<boolean>(
    () =>
      whiteLabelLoadingStatus === "succeeded" &&
      (companyId ? externalCompanyDataLoader === "succeeded" : true),
    [companyId, externalCompanyDataLoader, whiteLabelLoadingStatus],
  );

  const signUpRender = useCallback((): JSX.Element => {
    if (!signupEnabled && !companyId) return <Redirect to={ROUTES.login} />;

    if (companyId && !company) return <Redirect to={ROUTES.login} />;

    return <SignUp companyId={companyId} />;
  }, [company, companyId, signupEnabled]);

  useEffect(() => {
    if (isAuthenticationObserverSet && isUserAuthenticated)
      history.push(ROUTES.DEFAULT_ROUTE);
  }, [isAuthenticationObserverSet, history, isUserAuthenticated]);

  if (!isMainDataLoaded)
    return <Preloader modifier="global" className={styles.initialPreloader} />;

  return (
    <div className={styles.wrapper}>
      <AuthHeader />
      <div className={styles.content}>
        <Switch>
          <Route exact path={ROUTES.login}>
            <Login />
          </Route>
          <Route exact path={ROUTES.signUp} render={signUpRender} />
          <Route exact path={ROUTES.passwordReset}>
            <PasswordReset />
          </Route>
          <Route path={ROUTES.verifyEmailPage}>
            <EmailVerification />
          </Route>
          <Redirect to={ROUTES.login} />
        </Switch>
      </div>
      <Footer />
    </div>
  );
};

export default Auth;
