import { useCallback, useContext } from "react";

import context from "src/context";

export const useModal = () => {
  const { setModalElement } = useContext(context);

  const setModal = useCallback(
    (element: React.ReactNode) => setModalElement(element),
    [setModalElement],
  );

  const closeModal = useCallback(
    () => setModalElement(null),
    [setModalElement],
  );

  return { setModal, closeModal };
};
