import { useMemo, useCallback } from "react";
import copy from "copy-to-clipboard";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { useModal } from "src/hooks";
import { removeExtraSpaces, showToastNotification } from "src/utils";
import {
  EditInsightsWidgetDataModal,
  ConfirmResetInsightsWidgetDataModal,
} from "src/features";
import {
  selectUserId,
  selectWidgetData,
  selectDashboardById,
  selectIsCompanyDashboard,
} from "src/store/selectors";

// Inner imports
import { WIDGET_ID } from "../constants";

export const useInsightsActionButtons = ({
  trackersCollectionId,
  dashboardDateRangeId,
}: Widget.ChartProps): Widget.ActionButton[] => {
  const { t } = useTranslation();

  const { setModal } = useModal();

  const widgetData = useSelector((state: Store.RootState) =>
    selectWidgetData(state, dashboardDateRangeId, WIDGET_ID),
  );

  const isCompanyDashboard = useSelector((state: Store.RootState) =>
    selectIsCompanyDashboard(state, trackersCollectionId),
  );

  const dashboard = useSelector((state: Store.RootState) =>
    selectDashboardById(state, trackersCollectionId),
  );

  const userId = useSelector(selectUserId);

  const value = useMemo<string>(() => {
    const rawValue = widgetData?.data.aiPrompt.value;

    if (!rawValue) return "";

    return removeExtraSpaces(rawValue);
  }, [widgetData?.data.aiPrompt.value]);

  const isValueChanged = useMemo<boolean>(() => {
    if (!widgetData?.data.customPrompt.value) return false;

    const { customPrompt, aiPrompt } = widgetData.data;

    return customPrompt.value !== aiPrompt.value;
  }, [widgetData?.data]);

  const isUserDashboardOwner = useMemo<boolean>(() => {
    if (!dashboard) return false;

    return userId === dashboard.authorId;
  }, [dashboard, userId]);

  const onCopyValueToClipboard = useCallback((): void => {
    if (!value) return;

    copy(value);

    showToastNotification({
      id: "markdown-chart-copy-text",
      type: "success",
      text: t("widget.insights.status.success.text_copied"),
    });
  }, [value, t]);

  const onResetValueToClipboard = useCallback(
    (): void =>
      setModal(
        <ConfirmResetInsightsWidgetDataModal
          trackersCollectionId={trackersCollectionId}
          dashboardDateRangeId={dashboardDateRangeId}
        />,
      ),
    [setModal, trackersCollectionId, dashboardDateRangeId],
  );

  const onEditValueToClipboard = useCallback(
    (): void =>
      setModal(
        <EditInsightsWidgetDataModal
          trackersCollectionId={trackersCollectionId}
          dashboardDateRangeId={dashboardDateRangeId}
        />,
      ),
    [setModal, trackersCollectionId, dashboardDateRangeId],
  );

  return useMemo<Widget.ActionButton[]>(() => {
    const actionButtons: Widget.ActionButton[] = [];

    if (isUserDashboardOwner && isCompanyDashboard && isValueChanged)
      actionButtons.push({
        id: "insights-reset-value",
        icon: "Reset",
        tooltip: t("widget.insights.header.button.reset_text"),
        onClick: onResetValueToClipboard,
      });

    if (isUserDashboardOwner && isCompanyDashboard)
      actionButtons.push({
        id: "insights-edit-value",
        icon: "Edit",
        tooltip: t("widget.insights.header.button.edit_text"),
        onClick: onEditValueToClipboard,
      });

    if (value)
      actionButtons.push({
        id: "insights-copy-value",
        icon: "Clipboard",
        tooltip: t("widget.insights.header.button.copy_text"),
        onClick: onCopyValueToClipboard,
      });

    return actionButtons;
  }, [
    t,
    value,
    isValueChanged,
    isCompanyDashboard,
    isUserDashboardOwner,
    onCopyValueToClipboard,
    onEditValueToClipboard,
    onResetValueToClipboard,
  ]);
};
