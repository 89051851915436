import axios from "src/services/axios";
import firestore from "src/services/firestore";
import firebaseAuth from "src/services/firebaseAuth";
import {
  getHostDomain,
  triggerGtmEvent,
  isErrorTypeGuard,
  isAxiosErrorTypeGuard,
  isFirebaseAuthErrorTypeGuard,
} from "src/utils";
import { getTimestamps } from "../utils";
import { API_ENDPOINTS, COLLECTION_IDS } from "../constants";

// Inner imports
import * as schemas from "./userSchema";
import { CreateUserPayload } from "./userSlice";

const USER_API_ERROR: Record<
  | "invalidCredential"
  | "unknownUser"
  | "unverifiedEmail"
  | "existingEmail"
  | "authenticationError"
  | "tooManyRequests",
  string
> = {
  unknownUser: "User not found",
  unverifiedEmail: "Email not verified",
  existingEmail: "Email already exists",
  authenticationError: "Authentication error",
  invalidCredential: "Invalid email or password",
  tooManyRequests: "Access temporarily disabled due to too many requests",
};

export const subscribeOnUserHardReload = (): Function => {
  const authUser = firebaseAuth().currentUser;

  const docRef = firestore()
    .collection(COLLECTION_IDS.users)
    .doc(authUser?.uid);

  return docRef.onSnapshot((snap) => {
    const snapData = snap.data();

    if (!snapData) return;

    const { whetherItNeedsUpdate = false } = snapData?.hardReload || {};

    if (!whetherItNeedsUpdate) return;

    const changes = {
      whetherItNeedsUpdate: false,
      lastUpdate: new Date().toISOString(),
    };

    docRef
      .set({ hardReload: changes }, { merge: true })
      .then(() => window.location.reload());
  });
};

export const checkIsWhiteLabelUser = async (
  whiteLabelId: WhiteLabel.Data["id"],
  userId: User.Data["id"],
): Promise<boolean> => {
  const response = await axios.post(API_ENDPOINTS.checkIsWhiteLabelUser, {
    userId,
    whiteLabelId,
  });

  return schemas.isWhiteLabelUserSchema.validateSync(response.data);
};

export const checkEmailAvailability = (
  email: User.Data["email"],
): Promise<boolean> =>
  firebaseAuth()
    .fetchSignInMethodsForEmail(email)
    .then((value) => !value.length);

export const getUserById = async (
  id: User.Data["id"],
): Promise<User.CollectionType> => {
  const result = await firestore()
    .collection(COLLECTION_IDS.users)
    .doc(id)
    .get();

  if (!result.exists) throw Error(USER_API_ERROR.unknownUser);

  return schemas.userSchema.validateSync(result.data());
};

export const createUser = async (
  payload: CreateUserPayload,
): Promise<User.Data["id"]> => {
  try {
    const response = await axios.post(API_ENDPOINTS.createUser, payload);

    const userId = schemas.createUserSchema.validateSync(response.data);

    triggerGtmEvent("UserSignUp", {
      userId,
      companyId: payload.user.companyId,
    });

    return userId;
  } catch (error) {
    handleAuthError(error);
  }
};

export const updateUser = async ({
  id,
  changes: { email, ...restChanges },
}: Store.UpdateEntity<User.Data>): Promise<Store.UpdateEntity<User.Data>> => {
  if (email) {
    restChanges.emailVerified = false;
    restChanges.verifyEmailAttemptsNumber = 0;

    const isAvailable = await checkEmailAvailability(email);

    if (!isAvailable) throw Error(USER_API_ERROR.existingEmail);
  }

  const { updatedAt } = getTimestamps();

  const changes = { ...restChanges, updatedAt };

  await Promise.all([
    firestore()
      .collection(COLLECTION_IDS.users)
      .doc(id)
      .set(changes, { merge: true }),
    updateUserAuth({ email, ...restChanges }),
  ]);

  return { id, changes };
};

export const updateUserConfig = async ({
  id,
  changes,
}: Store.UpdateEntity<User.Data>): Promise<Store.UpdateEntity<User.Data>> => {
  await firestore()
    .collection(COLLECTION_IDS.users)
    .doc(id)
    .set(changes, { merge: true });

  return { id, changes };
};

export const deleteUser = (userId: User.Data["id"]): Promise<void> =>
  axios.post(API_ENDPOINTS.deleteUser, { userId });

export const updateUserPassword = (password: string): Promise<void> =>
  updateUserAuth({ password });

export const resetUserPassword = async (
  email: User.Data["email"],
  whiteLabel: WhiteLabel.Data,
): Promise<void> => {
  const { id: userId } = await getUserInfoByEmail(email);

  const isWhiteLabelUser = await checkIsWhiteLabelUser(whiteLabel.id, userId);

  if (!isWhiteLabelUser) throw Error(USER_API_ERROR.invalidCredential);

  return firebaseAuth().sendPasswordResetEmail(email);
};

export const verifyEmail = async ({
  token,
  userId,
  whiteLabel,
}: {
  token: string;
  userId: User.Data["id"];
  whiteLabel: WhiteLabel.Data;
}): Promise<string> => {
  try {
    const isWhiteLabelUser = await checkIsWhiteLabelUser(whiteLabel.id, userId);

    if (!isWhiteLabelUser) throw Error(USER_API_ERROR.invalidCredential);

    const response = await axios.post(API_ENDPOINTS.verifyEmail, {
      token,
    });

    const authToken = schemas.verifyEmailSchema.validateSync(response.data);

    triggerGtmEvent("UserVerifyEmail", { userId });

    return authToken;
  } catch (error) {
    handleAuthError(error);
  }
};

export const logInWithEmailAndPassword = async ({
  email,
  password,
  whiteLabel,
  unverifiedUserCallback,
}: {
  email: User.Data["email"];
  password: string;
  whiteLabel: WhiteLabel.Data;
  unverifiedUserCallback: (userId: User.Data["id"]) => void;
}): Promise<void> => {
  try {
    const { id: userId, emailVerified } = await getUserInfoByEmail(email);

    if (!userId) throw Error(USER_API_ERROR.invalidCredential);

    if (!emailVerified) {
      unverifiedUserCallback(userId);

      throw Error(USER_API_ERROR.unverifiedEmail);
    }

    const isWhiteLabelUser = await checkIsWhiteLabelUser(whiteLabel.id, userId);

    if (!isWhiteLabelUser) throw Error(USER_API_ERROR.invalidCredential);

    await firebaseAuth().setPersistence("local");

    await firebaseAuth()
      .signInWithEmailAndPassword(email, password)
      .then((credentials) => {
        triggerGtmEvent("UserLogIn", { userId });

        return credentials;
      });
  } catch (error) {
    handleAuthError(error);
  }
};

export const logInWithVerificationToken = async ({
  token,
  userId,
  whiteLabel,
}: {
  token: string;
  userId: User.Data["id"];
  whiteLabel: WhiteLabel.Data;
}): Promise<void> => {
  try {
    if (!userId) throw Error(USER_API_ERROR.invalidCredential);

    const isWhiteLabelUser = await checkIsWhiteLabelUser(whiteLabel.id, userId);

    if (!isWhiteLabelUser) throw Error(USER_API_ERROR.invalidCredential);

    const authToken = await verifyEmail({ userId, token, whiteLabel });

    await firebaseAuth().setPersistence("local");

    await firebaseAuth()
      .signInWithCustomToken(authToken)
      .then((credentials) => {
        triggerGtmEvent("UserLogIn", { userId });

        return credentials;
      });
  } catch (error) {
    handleAuthError(error);
  }
};

export const reauthenticateUser = async ({
  password,
}: {
  password: string;
}): Promise<void> => {
  const user = firebaseAuth().currentUser;

  if (!user?.email) return;

  const credential = firebaseAuth.EmailAuthProvider.credential(
    user.email,
    password,
  );

  await user.reauthenticateWithCredential(credential);
};

export const sendVerifyEmail = async (
  userId: User.Data["id"],
  whiteLabel: WhiteLabel.Data,
): Promise<void> => {
  const isWhiteLabelUser = await checkIsWhiteLabelUser(whiteLabel.id, userId);

  if (!isWhiteLabelUser) throw Error(USER_API_ERROR.unknownUser);

  return axios.post(API_ENDPOINTS.sendVerificationEmail, {
    userId,
    whiteLabelDomainId: getHostDomain(),
  });
};

export const getUsersEmailById = async (
  ids: User.Data["id"][],
): Promise<Record<User.Data["id"], User.Data["email"]>> => {
  const response = await axios.post(API_ENDPOINTS.getUserEmails, ids);

  const emails = schemas.usersEmailSchema.validateSync(response.data);

  return emails.reduce<Record<User.Data["id"], User.Data["email"]>>(
    (acc, email, index) => {
      const id = ids[index];

      if (id && email) acc[id] = email;

      return acc;
    },
    {},
  );
};

export const updateUserLastActiveAt = async (userId: string) => {
  try {
    await firestore()
      .collection(COLLECTION_IDS.users)
      .doc(userId)
      .update({ lastActiveAt: new Date().toISOString() });
  } catch (error) {
    console.error(error);
  }
};

export const reportError = async (metadata: {
  message: string;
}): Promise<void> => {
  try {
    await axios.post(API_ENDPOINTS.reportError, metadata);
  } catch (err) {
    console.error("Report error issue:", err);
  }
};

async function updateUserAuth(
  changes: Pick<
    Store.UpdateEntity<User.Data>["changes"],
    "firstName" | "email"
  > & {
    password?: string;
  },
): Promise<void> {
  const user = firebaseAuth().currentUser;

  if (!user) throw Error("Can not update user data in firebase auth");

  const promises: Promise<void>[] = [];

  const { firstName, email, password } = changes;

  if (firstName) promises.push(user.updateProfile({ displayName: firstName }));

  if (email) promises.push(user.updateEmail(email));

  if (password) promises.push(user.updatePassword(password));

  await Promise.all(promises);
}

async function getUserInfoByEmail(
  email: User.Data["email"],
): Promise<schemas.UserInfoSchemaType> {
  const response = await axios.post(API_ENDPOINTS.getAuthInfo, { email });

  return schemas.userInfoSchema.validateSync(response.data);
}

function handleAuthError(error: unknown): never {
  console.error(error);

  let message = "";

  if (isAxiosErrorTypeGuard(error)) {
    switch (error.response?.status) {
      case 404:
        message = USER_API_ERROR.invalidCredential;
        break;
      case 400:
        message = USER_API_ERROR.authenticationError;
        break;
      default:
        message = error.message;
    }
  } else if (isFirebaseAuthErrorTypeGuard(error)) {
    switch (error.code) {
      case "auth/wrong-password":
        message = USER_API_ERROR.invalidCredential;
        break;
      case "auth/too-many-requests":
        message = USER_API_ERROR.tooManyRequests;
        break;
      default:
        message = error.message;
    }
  } else if (isErrorTypeGuard(error)) {
    message = error.message;
  }

  throw new Error(message);
}
