import { FC } from "react";

import styles from "./SuggestedCategory.module.scss";

type Props = {
  category: Tracker.CategoryEntity;
  selectSuggestedCategoryHandler: (category: Tracker.CategoryEntity) => void;
};

export const SuggestedCategory: FC<Props> = ({
  category,
  selectSuggestedCategoryHandler,
}) => (
  <div
    className={styles.wrapper}
    onClick={() => selectSuggestedCategoryHandler(category)}
  >
    <div className={styles.content}>
      <div className={styles.header}>
        <span className={styles.name}>{category.dashboardName}</span>
      </div>
      <span className={styles.description}>
        {category.dashboardDescription}
      </span>
    </div>
  </div>
);
