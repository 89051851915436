import firestore from "src/services/firestore";
import { getHostDomain } from "src/utils";
import { COLLECTION_IDS } from "src/store/constants";

import * as schemas from "./whiteLabelSchema";

export const getWhiteLabel = async (): Promise<WhiteLabel.Data> => {
  const whiteLabelDomainDoc = await firestore()
    .collection(COLLECTION_IDS.whiteLabelDomains)
    .doc(getHostDomain())
    .get();

  if (!whiteLabelDomainDoc.exists) throw Error("White label doesn't exist");

  const { whiteLabelId } = schemas.whiteLabelDomainSchema.validateSync(
    whiteLabelDomainDoc.data(),
  );

  const whiteLabelDoc = await firestore()
    .collection(COLLECTION_IDS.whiteLabels)
    .doc(whiteLabelId)
    .get();

  const whiteLabel = schemas.whiteLabelSchema.validateSync(
    whiteLabelDoc.data(),
  );

  return {
    ...whiteLabel,
    id: whiteLabelDoc.id,
  };
};
